@import '_variables';

.edit-list_border-left {
  border-left: @border;
}

.edit-list_sm {
  &.edit-list__large_indent {
    padding: 10px 0;
  }

  .edit-list__row {
    padding: 5px 12px;

    &:not(:last-child) {
      border-bottom: none;
    }
  }

  .edit-list__row_spec {
    padding: 5px;
  }
}

.edit-list__row {
  padding: 15px;

  &:not(.edit-list__row_text-row) {
    display: flex;
    align-items: flex-start;
  }

  &:nth-child(even) {
    background-color: @soft-peach;
  }

  &:not(:last-child) {
    border-bottom: @border;
  }
}

.edit-list__title {
  flex-shrink: 0;
  padding: 5px 0;
  margin-right: 20px;
}

.edit-list__float-button {
  vertical-align: bottom;
  margin-left: 5px;
}

.edit-list__title_flex {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.edit-list__title_0 {
  margin-right: 0;
}

.edit-list__description {
  width: 100%;
  font-size: @secondary-font-size;
}

.edit-list__description_with-error {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 15px;
  }
}

.edit-list__value {
  width: 100%;
  align-self: center;
  word-break: break-word;
}

.word_break {
  word-wrap: break-word;
}

.edit-list__border {
  border: none;
}

.edit-list__title__fontWeight {
  font-weight: 600;
}