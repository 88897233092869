@import '_variables';

.tip {
  color: @deep-aqua;
  font-style: italic;
  font-size: @secondary-font-size;
}

.subtitle {
  font-size: 10px;
}

.subtitle_transparency {
  opacity: 0.5;
}

.title {
  font-size: @primary-font-size;
}

.title_xs {
  font-size: @secondary-font-size;
}

.title_reg {
  font-size: @primary-font-size;
}

.title_md {
  font-size: 18px;
  font-weight: 300;
}

.title_sm {
  font-size: 16px;
}

.title_lg {
  font-size: 24px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 600;
}

.text-light {
  font-weight: 300;
}

.icon-label {
  > * {
    display: inline;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.text-break-words {
  word-break: break-word;
}

.text__green {
  color: @la-salle-green;
}

.text__red {
  color: @milano-red;
}

.text__grey {
  color: @manatee;
}

.text__italic {
  font-style: italic;
}
