@import '_variables';

.board {
  background-color: @white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(@black, 0.1);
  position: relative;

  &:not(.board_embeded) {
    border: @border;
  }

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -1px;
    width: 10px;
    height: 10px;
    border: 1px solid #cbd4d8;
    border-top: none;
  }

  &:after {
    border-radius: 0 0 10px 0;
    border-left: none;
    right: -1px;
  }

  &:before {
    border-radius: 0 0 0 10px;
    border-right: none;
    left: -1px;
  }
}

.board_overflow {
  overflow: hidden;
}

.board_title {
  display: flex;
}

.board__header {
  background-color: @blue-whale;
  color: @white;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.board__title {
  padding: 10px 12px;
}

.board__header-actions {
  padding: 5px 12px;
  border-left: 1px solid rgba(@white, 0.1);
  display: inline-flex;
  align-items: center;
}

.board__image-container {
  border-right: @border;
  position: relative;
  flex-shrink: 0;
  max-width: 300px;
  overflow: hidden;
  & + .board__description {
    width: calc(100% - 300px);
  }
}

.board__description {
  width: 100%;
}

.board__description-section {
  padding: 20px;

  &:not(:first-child) {
    border-top: @border;
  }
}

.board__description-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.board__description-comment {
  font-size: @secondary-font-size;
}

.board__description-subtitle {
  font-size: @secondary-font-size;
  color: @grey-goose;
  letter-spacing: 0.5px;
}

.board__description-label {
  font-size: @secondary-font-size;
}

.board__description-text {
  margin-top: 15px;
}

.board__image-placeholder {
  min-height: 300px;
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;
  color: @grey-goose;

  img {
    width: 100%;
  }
}

.image-placeholder__description {
  font-size: @secondary-font-size;
}
