.compliance.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: white;
}

.compliance.table-striped > tbody > tr:nth-of-type(odd):hover {
  background-color: #f9f9f9;
}

.compliance.table-striped > tbody:nth-of-type(odd) > tr {
  background-color: #f2f4f5 !important;
}

.compliance.table-striped > tbody:nth-of-type(odd) > tr:hover {
  background-color: #eceeef !important;
}

.event-main-row > td {
  border-bottom: none !important;
}

/* REPORTS */
.compliance-status {
  text-align: center;
}

.compliance-status .passed {
  color: #51b055;
}

.compliance-status .failed {
  color: #f27483;
}

.compliance-status .in-progress {
  color: #4a86ba;
}

.compliance-status .fix-template {
  color: #f26c4f;
}

.compliance-status-icon_check {
  color: #51b055;
}

.compliance-status-icon_exclamation-triangle {
  color: #f27483;
}

.compliance-status-icon_gears {
  color: #4a86ba;
}

.compliance-status-icon_wrench {
  color: #f26c4f;
}
/* TEMPLATES */
.template-description {
  margin-bottom: 5px !important;
  display: block;
}

.templates-type-checkbox > .input-group {
  display: inline-block;
  vertical-align: sub;
}

.test-separator {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-top: 1px solid;
}

.template-drag-arrow {
  cursor: move !important;
}
.template-drag-placeholder {
  line-height: 40px;
  padding: 15px;
  background-color: #dedede;
  text-align: center;
  font-weight: 500;
}

.template-drag-placeholder.test-placeholder {
  line-height: 44px;
}
.caption.heavy {
  font-weight: 700 !important;
}

/* REPORT DETAILS*/

.buttons-block.report > button {
  margin-left: 5px;
}

.report-failed {
  color: red;
}

.report-failed label {
  color: red !important;
}

.report-passed {
  font-weight: 600;
}

.report-passed label {
  font-weight: 600 !important;
}

table.attachments .attachment-header.disabled {
  color: #999;
}

table.attachments .attachment-size {
  width: 100px;
  color: grey;
}

table.attachments .attachment-close {
  width: 50px;
}
.test-calculations-sign {
  color: grey;
  font-weight: 700 !important;
  font-size: 20px;
  text-align: center;
}
