@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url('../assets/fonts/open_sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype-variations');
  font-weight: 1 999;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  height: 100%;
}
body {
  height: 100%;
  min-width: 970px;
  font-size: 14px;
  font-weight: 400;
  background: #f5f6fa;
  font-family: 'Roboto', sans-serif;
  color: #002743;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: none;
}
small {
  font-size: smaller;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
* {
  line-height: 1.4;
  box-sizing: border-box;
  outline: none;
}
:focus {
  outline: 0;
}
svg {
  display: inline-block;
  fill: currentColor;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
}
address {
  font-style: normal;
}
b {
  font-weight: 600;
}
button,
a {
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  color: inherit;
  font-size: 12px;
}
button:disabled,
a:disabled {
  opacity: 0.4;
  pointer-events: none;
}
.disabled-state {
  opacity: 0.3;
}
.btn {
  display: inline-block;
  color: #002743;
  font-weight: 600;
  line-height: 18px;
  font-size: 13px;
  padding: 7px 14px;
}
.btn_iconed {
  display: inline-flex;
  flex-shrink: 0;
  align-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 0;
}
.btn_iconed:hover:not(:disabled) {
  color: #017d80;
  transition: 0.3s;
}
.btn_iconed-small {
  padding: 0 !important;
}
.btn_iconed-blue {
  padding: 0;
  margin: 0;
  color: #017d80;
  box-shadow: none;
  appearance: none;
}
.btn_iconed_warning {
  color: #e7b731;
}
.btn_iconed_warning:hover:not(:disabled),
.btn_iconed_warning:active:not(:disabled) {
  color: #e7b731;
}
.btn_iconed_input {
  margin-top: 5px;
}
.btn_iconed_success {
  color: #017d80;
}
.btn_iconed_white {
  color: #ffffff;
}
.btn_iconed_white:hover:not(:disabled),
.btn_iconed_white:active:not(:disabled) {
  color: #ffffff;
  opacity: 0.8;
}
.btn_iconed_danger {
  color: #b40909;
}
.btn_boxed {
  padding: 5px;
  border: 1px solid #ccd4d9;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.link {
  color: #017d80;
  font-size: inherit;
}
.link:hover:not(:disabled),
.link:active:not(:disabled) {
  color: #014c4d;
  text-decoration: underline;
}
.link.text-dotted {
  display: block;
}
.link_simple {
  color: inherit;
}
.link_simple:hover:not(:disabled),
.link_simple:active:not(:disabled) {
  color: inherit;
}
.buttons-block {
  padding: 15px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttons-block_to-end {
  justify-content: flex-end;
}
.buttons-block_to-center {
  justify-content: center;
}
.buttons-block_list {
  border: none;
  padding: 0;
  margin: 15px 0;
}
.buttons-block_list-pl {
  border: none;
  margin: 15px 0;
  padding: 0 15px;
}
.buttons-block_not-bordered {
  border: none;
}
.buttons-block_bordered {
  border: 1px solid #ccd4d9;
  border-top: 0;
}
.btn-collapse-icon {
  font-size: 14px;
  font-weight: 400;
}
.btn-collapse-icon span {
  margin-left: 5px;
}
/* specific buttons - small or in link-like design */
.btn-add-attribute {
  color: #48fe9b;
  font-weight: 600;
  padding: 6px 0;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
}
.btn-add-attribute > *:not(:last-child) {
  margin-right: 5px;
}
.btn-add-attribute_blue {
  color: #017d80;
}
.btn-add-attribute_neutral {
  color: #ccd4d9;
}
.btn-add-attribute_default {
  color: #000000;
}
.btn-add-attribute_neutral {
  color: #ccd4d9;
}
.notification-badge {
  position: absolute;
  top: -8px;
  font-size: 10px;
  background-color: #b40909;
  border-radius: 50%;
  z-index: 3;
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
}
.notification-badge.badge-right {
  right: -8px;
}
.notification-badge.badge-left {
  left: -8px;
}
.tooltip-btn_underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}
.action-btn {
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 7px 14px;
}
.action-btn_small {
  padding-top: 5px;
  padding-bottom: 5px;
}
.action-btn_xl {
  font-size: 16px;
  width: 245px;
  padding: 14px;
}
.action-btn_full {
  width: 100%;
  justify-content: center;
}
.action-btn__icon {
  margin-right: 8px;
  font-size: 0;
}
.action-btn_default {
  color: #002743;
  background-color: #ffffff;
  border: 1px solid #ccd4d9;
}
.action-btn_default:hover {
  background-color: #f2f2f2;
}
.action-btn_default.active {
  background-color: #b3b3b3;
}
.action-btn_success {
  color: #ffffff;
  background-color: #017d80;
  border: 1px solid #017d80;
}
.action-btn_success:hover {
  background-color: #016467;
}
.action-btn_success.active {
  background-color: #000000;
}
.action-btn_danger {
  color: #ffffff;
  background-color: #b40909;
  border: 1px solid #b40909;
}
.action-btn_danger:hover {
  background-color: #9c0808;
}
.action-btn_danger.active {
  background-color: #220202;
}
.action-btn_brightly {
  color: #002743;
  background-color: #48fe9b;
  border: 1px solid #48fe9b;
}
.action-btn_brightly:hover {
  background-color: #2ffe8d;
}
.action-btn_brightly.active {
  background-color: #01ac4f;
}
.btn-group {
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
}
.btn-group__wrapper {
  overflow: hidden;
}
.btn-group__btn,
.btn-group__wrapper {
  border: 1px solid rgba(1, 125, 128, 0.8);
}
.btn-group__btn:not(:disabled):hover,
.btn-group__wrapper:not(:disabled):hover {
  background-color: rgba(1, 174, 179, 0.1);
}
.btn-group__btn.active,
.btn-group__wrapper.active {
  background-color: rgba(1, 125, 128, 0.1);
}
.btn-group__btn:not(:last-child),
.btn-group__wrapper:not(:last-child) {
  border-right-color: transparent;
}
.btn-group__btn:first-child,
.btn-group__wrapper:first-child {
  border-radius: 50px 0 0 50px;
}
.btn-group__btn:last-child,
.btn-group__wrapper:last-child {
  border-radius: 0 50px 50px 0;
}
.btn-group__input {
  display: none;
}
.btn-group__input:checked + .btn-group__label {
  background-color: rgba(1, 125, 128, 0.1);
}
.btn-group__label {
  cursor: pointer;
  font-weight: 600;
  color: #002743;
  line-height: 18px;
  font-size: 13px;
  padding: 7px 14px;
  display: inline-block;
}
.ml-2 {
  margin-left: 6px;
}
.header {
  font-size: 12px;
  padding: 20px;
}
.header:not(.header_not-bordered) {
  border-bottom: 1px solid #ccd4d9;
}
.header_transparent {
  padding: 20px 0;
}
.header__title {
  font-size: 20px;
  word-wrap: break-word;
  color: #002743;
  letter-spacing: normal;
}
.header__title a {
  color: inherit;
  font-size: inherit;
}
td,
th {
  vertical-align: middle;
  padding: 12px;
  font-size: 12px;
  text-align: left;
  font-weight: normal;
}
.table tfoot {
  font-size: 14px;
}
.table > tbody > tr {
  background-color: #ffffff;
}
.table_narrow td,
.table_narrow th {
  padding: 5px 12px;
}
.table-striped > tbody > tr:nth-child(even) {
  background-color: #eeeff3;
}
.table-striped > tbody > tr:nth-child(even):hover,
.table-striped > tbody > tr:nth-child(even):focus {
  background-color: #eceeef;
}
.table_fixed {
  table-layout: fixed;
  min-width: 1100px;
}
.table_list > tbody > tr {
  border-bottom: 1px solid #ccd4d9;
}
.table_list > tbody > tr:nth-child(even) {
  background-color: #ffffff;
}
.table_list > tbody > tr:hover,
.table_list > tbody > tr:focus {
  background-color: #f5f5f5;
}
.table_big-font th,
.table_big-font td {
  font-size: 14px;
}
.table__footer-custom {
  border-top: 1px solid #ccd4d9;
  border-bottom: 1px solid #ccd4d9;
}
.table__footer-custom td {
  background-color: #ffffff !important;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 14px;
}
.table__cell_nested {
  padding: 0;
}
.table__wide-col {
  width: 350px;
  max-width: 350px;
}
@media all and (max-width: 1100px) {
  .table__wide-col {
    width: 25%;
  }
}
.table__small-col {
  width: 90px;
  max-width: 15vw;
}
.table__middle-col {
  width: 240px;
  max-width: 40vw;
}
.table__widest-col {
  width: 400px;
  max-width: 400px;
}
@media all and (max-width: 1100px) {
  .table__widest-col {
    width: 30%;
  }
}
.table__wide_40vw_col {
  width: 40vw;
  max-width: 40vw;
}
.table_pinned tbody tr:first-of-type {
  border-top: 1px solid transparent;
}
.table__col-number {
  width: 45px;
}
.table-scroll {
  width: 100%;
  overflow-x: auto;
}
/* styles for logs tablexs with hidden rows*/
.table_striped > tbody > tr:nth-child(4n-3),
.table_striped > tbody > tr:nth-child(4n) {
  background-color: #ffffff;
}
.table_striped > tbody > tr:nth-child(4n-2),
.table_striped > tbody > tr:nth-child(4n-1) {
  background-color: #eeeff3;
}
.table__cropped-col {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table_bordered {
  border: 1px solid #ccd4d9;
}
.table_bordered .table__header {
  border-top: none;
}
.table__header {
  border-top: 1px solid #ccd4d9;
  border-bottom: 1px solid #ccd4d9;
  text-align: left;
}
.table__header tr:not(:first-child) {
  border-top: 1px solid #ccd4d9;
}
.table__header th {
  background-color: #e5e9ec;
  white-space: nowrap;
  font-weight: 600;
}
.table__header th:not(:last-child) {
  border-right: 1px solid #ccd4d9;
}
.table__header .table__pinned-col {
  box-shadow: 1px 0 0 0 #ccd4d9;
}
.table__header_transparent > tr {
  background-color: transparent;
}
.table__header_transparent > th {
  white-space: nowrap;
}
.table__header_transparent > th:not(:last-child) {
  border: none;
}
.table__sorting-column {
  cursor: pointer;
  position: relative;
  padding-right: 25px;
}
.table__sorting-column:after,
.table__sorting-column:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20491.996%20491.996%22%20enable-background=%22new%200%200%20491.996%20491.996%22%20width=%2210%22%20height=%228%22%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23ccd4d9%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d%3D%22M484.132%20124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208%200-13.964%202.792-19.036%207.86l-183.84%20183.848L62.056%20108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968%202.788-19.036%207.856l-16.12%2016.128c-10.496%2010.488-10.496%2027.572%200%2038.06l219.136%20219.924c5.064%205.064%2011.812%208.632%2019.084%208.632h.084c7.212%200%2013.96-3.572%2019.024-8.632l218.932-219.328c5.072-5.064%207.856-12.016%207.864-19.224%200-7.212-2.792-14.068-7.864-19.128z%22%2F%3E%3C%2Fsvg%3E);
}
.table__sorting-column:after {
  top: 50%;
}
.table__sorting-column:before {
  bottom: 50%;
  transform: rotate(180deg);
}
.table__sorting-column_desc:after {
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20491.996%20491.996%22%20enable-background=%22new%200%200%20491.996%20491.996%22%20width=%2210%22%20height=%228%22%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23000000%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d%3D%22M484.132%20124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208%200-13.964%202.792-19.036%207.86l-183.84%20183.848L62.056%20108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968%202.788-19.036%207.856l-16.12%2016.128c-10.496%2010.488-10.496%2027.572%200%2038.06l219.136%20219.924c5.064%205.064%2011.812%208.632%2019.084%208.632h.084c7.212%200%2013.96-3.572%2019.024-8.632l218.932-219.328c5.072-5.064%207.856-12.016%207.864-19.224%200-7.212-2.792-14.068-7.864-19.128z%22%2F%3E%3C%2Fsvg%3E);
}
.table__sorting-column_asc:before {
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20491.996%20491.996%22%20enable-background=%22new%200%200%20491.996%20491.996%22%20width=%2210%22%20height=%228%22%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23000000%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d%3D%22M484.132%20124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208%200-13.964%202.792-19.036%207.86l-183.84%20183.848L62.056%20108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968%202.788-19.036%207.856l-16.12%2016.128c-10.496%2010.488-10.496%2027.572%200%2038.06l219.136%20219.924c5.064%205.064%2011.812%208.632%2019.084%208.632h.084c7.212%200%2013.96-3.572%2019.024-8.632l218.932-219.328c5.072-5.064%207.856-12.016%207.864-19.224%200-7.212-2.792-14.068-7.864-19.128z%22%2F%3E%3C%2Fsvg%3E);
}
th.table__wrapped-col {
  white-space: normal;
}
body:not(.ie11) .table__pinned-col {
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: inherit;
}
body:not(.ie11) th.table__pinned-col {
  border: none;
  box-shadow: inset -1px 0 0 0 #ccd4d9;
  background-color: #e5e9ec;
}
body:not(.ie11) .table__pinned-col {
  min-width: 120px;
  max-width: 120px;
}
body:not(.ie11) .table__pinned-col_wide {
  min-width: 200px;
  max-width: 200px;
}
body:not(.ie11) .table__pinned-col_extra-wide {
  min-width: 363px;
  max-width: 363px;
  left: 0;
}
body:not(.ie11) .table__pinned-col_sm {
  min-width: 110px;
  max-width: 110px;
}
body:not(.ie11) .table__pinned-col_opt {
  min-width: 44px;
  max-width: 44px;
}
body:not(.ie11) .table__pinned-col.table__pinned-col-2 {
  left: 44px;
}
body:not(.ie11) .table__pinned-col.table__pinned-col-3 {
  left: 244px;
}
body:not(.ie11) .table__pinned-col.table__pinned-col-4 {
  left: 364px;
}
body:not(.ie11) .table__pinned-col.table__pinned-col-5 {
  left: 484px;
}
body:not(.ie11) .table__pinned-col.table__pinned-col-6 {
  left: 604px;
}
body:not(.ie11) .table__pinned-col.table__pinned-col-7 {
  left: 724px;
}
body:not(.ie11) .table__pinned-col_sm.table__pinned-col-4 {
  left: 364px;
}
body:not(.ie11) .table__pinned-col_sm.table__pinned-col-5 {
  left: 484px;
}
body:not(.ie11) .table__pinned-col_sm.table__pinned-col-6 {
  left: 594px;
}
body:not(.ie11) .table__pinned-col_sm.table__pinned-col-7 {
  left: 704px;
}
body:not(.ie11) .table__pinned-col_sm.table__pinned-col-8 {
  left: 844px;
}
body:not(.ie11) .table__pinned-col_sm.table__pinned-col-9 {
  left: 954px;
}
.table__pinned-col_last {
  box-shadow: inset -1px 0 0 0 #ccd4d9;
}
.table__pinned-col_last:after {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  height: 100%;
  width: 10px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
}
.table__row-title {
  font-weight: 600;
  color: #017d80;
}
.column_mustard {
  background-color: rgba(231, 183, 49, 0.1);
}
.column_blue {
  background-color: rgba(1, 125, 128, 0.1);
}
.column_green {
  background-color: rgba(72, 254, 155, 0.1);
}
.record-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-actions__status {
  display: inline-block;
  margin-right: 10px;
  color: inherit;
}
.table-location--buildings th:first-child,
.table-location--buildings td:first-child {
  padding-left: 20px;
}
.table-location--buildings td:last-child {
  padding-right: 30px;
}
tr.disabled__text > td {
  color: #ccd4d9;
}
.table__col-actions {
  width: 80px;
  max-width: 80px;
}
.toolbar__panel:not(.toolbar__panel_outer) {
  padding: 10px;
}
.toolbar__panel:not(.toolbar__panel_not-bordered) {
  border-top: 1px solid #ccd4d9;
}
.toolbar__panel_form {
  margin-bottom: -10px;
}
.toolbar__panel_outer {
  padding: 10px 0;
}
.toolbar__panel-bottom {
  border-bottom: 1px solid #ccd4d9;
}
.toolbar__panel_negative-margin {
  margin-bottom: -1px;
}
::input-placeholder {
  color: #ccd4d9;
  font-size: 12px;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
}
::placeholder {
  color: #ccd4d9;
  font-size: 12px;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
}
:input-placeholder {
  color: #ccd4d9;
  font-size: 12px;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
}
:placeholder {
  color: #ccd4d9;
  font-size: 12px;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
}
/* fix for ie11 clear icon in input */
::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* input type="number" - hide spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}
input[type="number"] {
  appearance: textfield;
}
/* end */
.mixin-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.38);
  background-color: #e5e9ec;
}
input,
select,
textarea {
  border: none;
  color: #002743;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  line-height: 18px;
}
input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: inherit;
}
input:disabled,
select:disabled,
textarea:disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.38);
  background-color: #e5e9ec;
}
input,
textarea {
  font-size: 12px;
  padding: 6px 12px;
  background-color: #ffffff;
  border-radius: 4px;
}
input:hover:not(:disabled, :read-only),
textarea:hover:not(:disabled, :read-only) {
  background-color: #f5f6fa;
}
input:focus,
textarea:focus {
  background-color: #ffffff;
  border: 1px solid #017d80;
  box-shadow: 0 0 4px rgba(51, 122, 183, 0.3);
}
textarea {
  resize: vertical;
}
select {
  appearance: none;
}
select::-ms-expand {
  display: none;
}
select:hover:not(:disabled) {
  background-color: #f5f6fa;
}
select:focus {
  background-color: #ffffff;
  border: 1px solid #017d80;
  box-shadow: 0 0 4px rgba(51, 122, 183, 0.3);
}
.select {
  font-size: 12px;
  padding: 6px 22px 6px 12px;
  text-overflow: ellipsis;
  background: #ffffff no-repeat center url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20386.257%20386.257%22%20enable-background=%22new%200%200%20386.257%20386.257%22%20width=%2210%22%20height=%228%22%3e%3cstyle%20type=%22text/css%22%3e.arrow%7Bfill%3A%23002743%3B%7D%3C%2Fstyle%3e%3cpath%20class=%22arrow%22%20d=%22M0%2096.879l193.129%20192.5%20193.128-192.5z%22/%3e%3c/svg%3e);
  background-position: calc(100% - 5px) center;
  background-size: 12px;
  cursor: pointer;
}
.select_xs {
  width: 70px;
}
.select_narrow {
  width: 100px;
}
.form-block {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: block;
}
.form-block_label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}
.form-block_readonly {
  padding: 6px 0;
  font-size: 12px;
}
.form-block_icon,
.form-block_icon_before {
  position: relative;
}
.form-block_icon::after,
.form-block_icon_before::after {
  content: "$";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.form-block_sm {
  width: 197.5px;
}
.form-block_md {
  width: 230px;
}
.form-block_xxs {
  width: 60px;
}
.form-block_xs {
  width: 160px;
}
.form-block_xxs {
  width: 110px;
}
.form-block_lg {
  max-width: initial;
}
.form-block_full-width {
  max-width: initial;
}
.form-block__tooltip {
  color: #ccd4d9;
  padding: 5px 0;
}
.form-block__element {
  border: 1px solid #ccd4d9;
  border-radius: 4px;
}
.form-block__element_with-batch {
  border-radius: 4px 0 0 4px;
}
.form-block__element_md {
  padding: 18px;
  font-size: 14px;
}
.form-block__element_icon {
  padding-left: 25px;
  text-align: right;
}
.form-block__element_not-input {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  cursor: auto;
}
.form-block__element_not-input:hover {
  background-color: transparent;
}
.form-block__element_not-input:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.form-block__label {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}
.form-block__input-label {
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #eeeff3;
  font-size: 12px;
  font-weight: 600;
  border-left: 1px solid #ccd4d9;
  overflow: visible;
  width: 50%;
}
.form-block__input-small {
  width: 50%;
}
.form-block__btn {
  flex-shrink: 0;
  align-self: center;
}
.form-block__element_xsmall {
  width: 50px;
}
.form-block__element_small {
  width: 100px;
}
.form-block__element_middle {
  width: 197.5px;
}
.form-block__element_wide {
  width: 100%;
  max-width: 400px;
}
.form-block__wrapper {
  flex-wrap: wrap;
}
.form-block__error_wide {
  width: 100%;
}
.form-block_error,
.form-block_error .multiselect {
  background-color: #f5f5f5 !important;
  border-color: #b40909 !important;
}
.form-block_warn,
.form-block_warn .multiselect,
.form-block_warn .form-block__element {
  border-color: sandybrown !important;
}
.error-block {
  padding: 5px 10px;
}
.error {
  color: #b40909;
  font-size: 12px;
  font-weight: 600;
  word-break: break-word;
}
.error:not(.error_inline) {
  margin-top: 5px;
}
.error_inherit {
  font-size: inherit;
}
.tooltip-placeholder {
  cursor: pointer;
}
.tooltip-btn {
  color: #017d80;
}
.tooltip-btn_error {
  color: #b40909;
  z-index: 1;
}
.btn.tooltip-btn_error {
  position: absolute;
  right: 6px;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  line-height: 1;
}
.btn.tooltip-btn_error.daterangepicker-tooltip-btn_error {
  right: 31px;
}
.select ~ .tooltip-btn_error {
  right: 23px;
}
.btn.tooltip-btn_static {
  position: relative;
  transform: none;
  right: 0;
}
.tooltip-btn_disable {
  color: #ccd4d9;
}
.error_warn {
  color: sandybrown;
}
.caption {
  padding: 6px 10px 0 0;
}
.small-height__textarea {
  height: 46px;
  min-height: 46px;
  overflow-y: auto;
  background-color: transparent;
  border: 1px solid #ccd4d9;
}
.form-block__multiselect {
  display: inline-block;
}
.success-msg {
  color: #017d80;
}
.override-disabled .multiselect {
  padding-left: 0 !important;
}
.override-disabled button.multiselect__btn {
  text-indent: 10px;
}
.override-disabled button.multiselect__btn:disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.38);
  background-color: #e5e9ec;
  opacity: 1;
}
.custom-radiobutton__input {
  display: none;
}
.custom-radiobutton__input:checked + .custom-radiobutton {
  background-color: #017d80;
  color: #ffffff;
}
.custom-checkbox,
.custom-radiobutton {
  color: #002743;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.custom-checkbox:hover .custom-checkbox__check_error,
.custom-checkbox:focus .custom-checkbox__check_error {
  background-color: #b40909;
}
.custom-checkbox:hover .custom-checkbox__check_error:after,
.custom-checkbox:focus .custom-checkbox__check_error:after {
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20365.696%20365.696%22%20enable-background=%22new%200%200%20365.696%20365.696%22%20%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23b40909%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d=%22M159.988%20318.582c-3.988%204.012-9.43%206.25-15.082%206.25s-11.094-2.238-15.082-6.25L9.375%20198.113c-12.5-12.5-12.5-32.77%200-45.246l15.082-15.086c12.504-12.5%2032.75-12.5%2045.25%200l75.2%2075.203L348.104%209.781c12.504-12.5%2032.77-12.5%2045.25%200l15.082%2015.086c12.5%2012.5%2012.5%2032.766%200%2045.246zm0%200%22/%3e%3c/svg%3e);
}
.custom-radiobutton:hover .custom-radiobutton__input:checked + .custom-radiobutton__check,
.custom-radiobutton:focus .custom-radiobutton__input:checked + .custom-radiobutton__check {
  border-color: #017d80;
}
.custom-radiobutton_xs {
  font-size: 12px;
}
.custom-checkbox_lg {
  font-size: 14px;
}
.custom-checkbox__input:not(:disabled) + .custom-checkbox__check:hover,
.custom-checkbox__input:not(:disabled) + .custom-radiobutton__check:hover,
.custom-checkbox__input:not(:disabled) + .custom-checkbox__check:focus,
.custom-checkbox__input:not(:disabled) + .custom-radiobutton__check:focus {
  border-color: #017d80;
}
.custom-checkbox__check,
.custom-radiobutton__check {
  display: block;
  height: 20px;
  width: 20px;
  border: 1px solid #ccd4d9;
  background-color: #ffffff;
  transition: all 0.4s;
  flex-shrink: 0;
  position: relative;
  border-radius: 4px;
}
.custom-checkbox__check:after,
.custom-radiobutton__check:after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.custom-radiobutton__check {
  border-radius: 50%;
}
.custom-radiobutton__check.full-size {
  font-size: 12px;
  height: 18px;
  width: 18px;
}
.custom-radiobutton__check.full-size:after {
  width: 100%;
  height: 100%;
}
.custom-checkbox__check_labeled,
.custom-radiobutton__check_labeled {
  margin-right: 5px;
}
.custom-checkbox_disabled,
.custom-radiobutton_disabled {
  cursor: default;
  opacity: 0.6;
}
.custom-checkbox__input {
  display: none;
}
.custom-checkbox__input:checked + .custom-checkbox__check {
  background-color: #017d80;
}
.custom-checkbox__input:checked + .custom-checkbox__check:after {
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20365.696%20365.696%22%20enable-background=%22new%200%200%20365.696%20365.696%22%20%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23ffffff%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d=%22M159.988%20318.582c-3.988%204.012-9.43%206.25-15.082%206.25s-11.094-2.238-15.082-6.25L9.375%20198.113c-12.5-12.5-12.5-32.77%200-45.246l15.082-15.086c12.504-12.5%2032.75-12.5%2045.25%200l75.2%2075.203L348.104%209.781c12.504-12.5%2032.77-12.5%2045.25%200l15.082%2015.086c12.5%2012.5%2012.5%2032.766%200%2045.246zm0%200%22/%3e%3c/svg%3e);
}
.custom-checkbox__input:checked + .custom-checkbox__check_error {
  background-color: #b40909;
}
.custom-checkbox__input:checked + .custom-checkbox__check_error:after {
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20365.696%20365.696%22%20enable-background=%22new%200%200%20365.696%20365.696%22%20%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23b40909%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d=%22M159.988%20318.582c-3.988%204.012-9.43%206.25-15.082%206.25s-11.094-2.238-15.082-6.25L9.375%20198.113c-12.5-12.5-12.5-32.77%200-45.246l15.082-15.086c12.504-12.5%2032.75-12.5%2045.25%200l75.2%2075.203L348.104%209.781c12.504-12.5%2032.77-12.5%2045.25%200l15.082%2015.086c12.5%2012.5%2012.5%2032.766%200%2045.246zm0%200%22/%3e%3c/svg%3e);
}
.custom-checkbox__input:disabled + .custom-checkbox__check {
  cursor: default;
  opacity: 0.6;
}
.custom-radiobutton__input {
  display: none;
}
.custom-radiobutton__input:checked + .custom-radiobutton__check:after {
  background-color: #017d80;
  border-radius: 50%;
}
.custom-radiobutton__input:checked + .custom-checkbox__check_error {
  background-color: #b40909;
}
.custom-radiobutton__input:checked + .custom-checkbox__check_error:after {
  background: no-repeat url(data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20365.696%20365.696%22%20enable-background=%22new%200%200%20365.696%20365.696%22%20%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A%23b40909%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d=%22M159.988%20318.582c-3.988%204.012-9.43%206.25-15.082%206.25s-11.094-2.238-15.082-6.25L9.375%20198.113c-12.5-12.5-12.5-32.77%200-45.246l15.082-15.086c12.504-12.5%2032.75-12.5%2045.25%200l75.2%2075.203L348.104%209.781c12.504-12.5%2032.77-12.5%2045.25%200l15.082%2015.086c12.5%2012.5%2012.5%2032.766%200%2045.246zm0%200%22/%3e%3c/svg%3e);
}
.custom-radiobutton__input:disabled + .custom-radiobutton__check {
  cursor: default;
  opacity: 0.6;
}
.label-checkbox__check {
  margin: 0 10px;
}
.filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.filter:not(.filter__unbordered) {
  border-top: 1px solid #ccd4d9;
}
.filter:not(.filter__no-padding) {
  padding: 10px 0 10px 10px;
}
.filter.filter__no_padding-top {
  padding-top: 0;
}
.filter label {
  position: relative;
}
.filter__no-padding {
  margin-right: -10px;
}
.filter__dropdown {
  width: calc((100% - 60px) / 6);
  margin-bottom: 10px;
  min-width: 197.5px;
  margin-right: 10px;
  position: relative;
}
.filter__dropdown.filter__not_bordered {
  border-top: none;
  padding-top: 0;
}
.filter__dropdown .filter__dropdown {
  margin-bottom: 10px;
}
.filter__dropdown.filter__dropdown-limited-w {
  width: 197.5px;
}
.filter__dropdown_no-mrgn {
  margin-bottom: 0;
}
.filter__extended-filter {
  margin: 0 10px 10px 0;
  display: inline-flex;
}
.filter__extended-label {
  padding-top: 7px;
  padding-bottom: 7px;
}
.filter__bordered-bottom {
  border-bottom: 1px solid #ccd4d9;
}
.filter-label {
  font-size: 12px;
}
.avatar-holder {
  display: flex;
  align-items: center;
}
.avatar-holder__image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 15px;
}
.image-holder__zoomed + .image-holder__image {
  max-width: 40px;
  max-height: 40px;
}
.edit-logo-holder {
  display: inline-block;
}
.edit-logo-holder__img {
  max-width: initial;
  width: 100%;
}
.edit-logo-holder_rounded {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  max-height: 100px;
}
.image-holder {
  width: 40px;
  height: 40px;
  max-height: 40px;
  text-align: center;
  margin: 0 auto;
}
.image-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
}
.image-holder > img {
  max-width: 40px;
  max-height: 40px;
  margin: 0;
}
.image-holder__image {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.img-zoomed {
  width: 200px;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #ccd4d9;
  line-height: 0;
}
.image-holder__zoomed {
  width: 200px;
  position: fixed;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #ccd4d9;
  line-height: 0;
  z-index: 3;
  visibility: hidden;
}
.image-holder__zoomed_show {
  visibility: inherit;
}
.datepicker-block {
  max-width: 100%;
  display: flex;
  border-radius: 4px;
  border: 1px solid #ccd4d9;
  overflow: hidden;
}
.datepicker-block.datepicker-block__display-block {
  display: block;
}
.datepicker-block .mat-icon {
  height: 37px;
  width: initial !important;
}
.datepicker-block .mat-mdc-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px !important;
  height: 32px !important;
  font-size: 0 !important;
  background-color: #eeeff3;
  border-left: 1px solid #ccd4d9;
  border-radius: 0;
}
.datepicker-block .mat-mdc-icon-button svg {
  width: 13px;
  height: 13px;
}
.datepicker-block .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0;
}
.datepicker-block .mat-mdc-text-field-wrapper:hover,
.datepicker-block .mat-mdc-text-field-wrapper input:focus {
  background-color: #f5f6fa;
}
.datepicker-block .mat-mdc-text-field-wrapper:has(input:focus) {
  border: 1px solid #017d80;
  background-color: #f5f6fa;
}
.datepicker-block .mat-mdc-text-field-wrapper input:focus::placeholder {
  color: transparent;
}
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.datepicker-block .mdc-text-field--filled,
.daterangepicker-block .mdc-text-field--filled {
  border-radius: var(--mdc-filled-text-field-container-shape);
}
.datepicker-block .mat-mdc-text-field-wrapper .mdc-line-ripple,
.daterangepicker-block .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}
.datepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay,
.daterangepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  background-color: #ffffff;
  opacity: 1;
}
.datepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
.daterangepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  align-items: center;
  font-size: small;
}
.datepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
.daterangepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  display: inline-flex;
  align-items: center;
}
.datepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input:disabled,
.daterangepicker-block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input:disabled {
  background-color: transparent;
}
.datepicker-block_error {
  border-color: #b40909;
}
.datepicker-block_narrow {
  width: 130px;
}
.datepicker-split {
  width: 134px;
}
.datepicker-block_wide {
  width: 400px;
}
.datepicker-block_middle {
  width: 197.5px;
}
.datepicker-block__btn {
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.datepicker-block__input {
  border-right: 1px solid #ccd4d9;
  width: calc(100% - 30px);
}
app-fiscal-header + .mat-calendar-content mat-year-view .mat-calendar-body-label[colspan="4"] {
  display: none !important;
}
.daterangepicker-block .mat-form-field-infix {
  display: inline-flex;
  align-items: center;
}
.daterangepicker-block.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 50%;
  transform: translateY(-50%) perspective(100px);
}
.daterangepicker-block .mat-datepicker-toggle .mat-mdc-button-base {
  width: 30px;
  height: 31.3333px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.daterangepicker-block .mat-datepicker-toggle .mat-mdc-icon-button svg {
  width: 20px;
}
.daterangepicker-block .mat-date-range-input-start-wrapper {
  margin-left: 12px;
}
.daterangepicker-block .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  top: 51%;
  transform: translateY(-50%);
}
.daterangepicker-block .mat-date-range-input-inner,
.daterangepicker-block .mat-date-range-input-mirror {
  font-size: 12px !important;
  vertical-align: middle !important;
}
.daterangepicker-block input:focus {
  border: none;
}
.daterangepicker-block input:hover:not(:disabled, :read-only),
.daterangepicker-block input:focus {
  background-color: transparent;
}
.projectbuilder-modal-open .mat-calendar-body-selected,
.projectbuilder-modal-open .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(
      .mat-calendar-body-selected,
      .mat-calendar-body-comparison-identical
    ) {
  background-color: #017d80 !important;
  color: #ffffff;
}
.projectbuilder-modal-open .mat-calendar-body-today:not(
      .mat-calendar-body-selected,
      .mat-calendar-body-comparison-identical
    ) {
  border-color: #00000061;
}
.projectbuilder-modal-open .mat-calendar-body-in-range::before {
  background-color: rgba(1, 125, 128, 0.1);
}
.projectbuilder-modal-open .mat-mdc-icon-button svg {
  fill: #4c4e51;
}
.projectbuilder-modal-open .mat-datepicker-actions .action-btn {
  border-radius: 8px;
  padding: 0 10px;
  line-height: 32px;
}
.projectbuilder-modal-open .mat-datepicker-actions .mat-mdc-raised-button {
  letter-spacing: 0;
}
.projectbuilder-modal-open .mat-datepicker-actions .action-btn_default {
  line-height: 30px;
  border: 1.5px solid #ccd4d9;
}
.projectbuilder-modal-open .mat-mdc-raised-button.action-btn_success {
  color: #ffffff;
  background-color: #017d80;
  border: 1px solid #017d80;
}
.projectbuilder-modal-open .mat-mdc-raised-button.action-btn_success:hover {
  background-color: #016467;
}
.projectbuilder-modal-open .mat-mdc-raised-button.action-btn_success.active {
  background-color: #000000;
}
.board {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.board:not(.board_embeded) {
  border: 1px solid #ccd4d9;
}
.board:before,
.board:after {
  display: block;
  content: '';
  position: absolute;
  bottom: -1px;
  width: 10px;
  height: 10px;
  border: 1px solid #cbd4d8;
  border-top: none;
}
.board:after {
  border-radius: 0 0 10px 0;
  border-left: none;
  right: -1px;
}
.board:before {
  border-radius: 0 0 0 10px;
  border-right: none;
  left: -1px;
}
.board_overflow {
  overflow: hidden;
}
.board_title {
  display: flex;
}
.board__header {
  background-color: #002743;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.board__title {
  padding: 10px 12px;
}
.board__header-actions {
  padding: 5px 12px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  display: inline-flex;
  align-items: center;
}
.board__image-container {
  border-right: 1px solid #ccd4d9;
  position: relative;
  flex-shrink: 0;
  max-width: 300px;
  overflow: hidden;
}
.board__image-container + .board__description {
  width: calc(100% - 300px);
}
.board__description {
  width: 100%;
}
.board__description-section {
  padding: 20px;
}
.board__description-section:not(:first-child) {
  border-top: 1px solid #ccd4d9;
}
.board__description-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}
.board__description-comment {
  font-size: 12px;
}
.board__description-subtitle {
  font-size: 12px;
  color: #ccd4d9;
  letter-spacing: 0.5px;
}
.board__description-label {
  font-size: 12px;
}
.board__description-text {
  margin-top: 15px;
}
.board__image-placeholder {
  min-height: 300px;
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;
  color: #ccd4d9;
}
.board__image-placeholder img {
  width: 100%;
}
.image-placeholder__description {
  font-size: 12px;
}
.category-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: -5px;
  margin-right: -5px;
  justify-content: flex-end;
}
.category-tags__tag {
  padding: 3px 7px;
  border-radius: 4px;
  font-size: 10px;
  margin: 0 5px 5px 0;
  font-weight: 600;
}
/* facility attributes colorpicker*/
.color-picker.open {
  border: 1px solid #ccd4d9;
  border-radius: 4px;
}
.color-picker.open .preset-area {
  padding: 0 18px 20px;
}
.color-picker .preset-area .colorpicker-presets .preset-color {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 2px 2px 4px;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
.color-picker .preset-area .colorpicker-presets .preset-color:before {
  content: attr(data-before);
  font-size: 10px;
  font-weight: 600;
  color: inherit;
  line-height: 20px;
  position: absolute;
  vertical-align: middle;
  left: 0;
  top: 0;
  width: 20px;
  text-align: center;
}
.color-picker.open .hue-alpha.box,
.color-picker.open .preset-area hr {
  display: none !important;
}
.color-picker.open .preset-area .preset-label {
  font-size: 14px;
  color: #444;
  padding: 20px 0;
  float: left;
}
.color-picker.open .button-area {
  text-align: center;
}
.color-picker .button-area button.btn-close {
  font-size: 18px;
  font-weight: 400;
  color: #444;
  padding: 0;
  width: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  position: absolute;
  top: 17px;
  right: 15px;
}
.color-picker .button-area button.btn-close:hover {
  opacity: 0.8;
}
.color-picker .button-area button.btn.btn-cancel {
  border: none;
  box-shadow: none;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 20px;
  line-height: 1;
}
.dndPlaceholder {
  margin: 20px 0;
  min-height: 50px;
  border: 1px dashed green;
  background-color: rgba(0, 0, 0, 0.1);
}
.dndPlaceholder.oneLineHeight {
  min-height: 42px;
  margin: 0;
}
.custom-radio {
  color: #002743;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.custom-radio.disabled {
  cursor: default;
  color: #ccd4d9;
}
.custom-radio_lg {
  font-size: 14px;
}
.custom-radio__check {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid #ccd4d9;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 0.4s;
  flex-shrink: 0;
  position: relative;
  vertical-align: bottom;
}
.custom-radio__check:after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.custom-radio__check_labeled {
  margin-right: 15px;
}
.custom-radio__input {
  display: none;
}
.custom-radio__input:checked + .custom-radio__check {
  background-color: #ffffff;
}
.custom-radio__input:checked + .custom-radio__check:after {
  background-color: #017d80;
}
.custom-radio__input:checked + .custom-radio__check_error:after {
  background-color: #b40909;
}
.custom-radio__input:disabled + .custom-radio__check {
  cursor: default;
  opacity: 0.6;
}
.custom-toggle {
  display: inline-block;
}
.custom-toggle__check {
  display: block;
  height: 15px;
  width: 30px;
  cursor: pointer;
  border: 1px solid #ccd4d9;
  border-radius: 40px;
  background-color: #707070;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}
.custom-toggle__check:before,
.custom-toggle__check:after {
  display: inline-block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
}
.custom-toggle__check:after {
  content: '';
  right: 1px;
}
.custom-toggle__check:before {
  content: '';
  left: 1px;
  opacity: 0;
}
.custom-toggle__check_sm {
  height: 13px;
  width: 25px;
}
.custom-toggle__check_lg {
  height: 18px;
  width: 36px;
}
.custom-toggle__toggler {
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  transition: left 0.3s ease-in-out;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
  background-color: #ffffff;
}
.custom-toggle__toggler_sm {
  left: 2px;
}
.custom-toggle__toggler_lg {
  left: 4px;
  width: 10px;
  height: 10px;
}
.custom-toggle__check_lock:after {
  background: no-repeat center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 20 20'%3E%3Cpath d='M15.236.5a4.526 4.526 0 0 0-4.524 4.524v1.81H2.57c-.995 0-1.81.805-1.81 1.809v9.047c0 1.005.815 1.81 1.81 1.81h10.857c1.005 0 1.81-.805 1.81-1.81V8.643a1.81 1.81 0 0 0-1.81-1.81h-.904v-1.81a2.71 2.71 0 0 1 2.714-2.713 2.71 2.71 0 0 1 2.714 2.714v1.81h1.81v-1.81A4.525 4.525 0 0 0 15.236.5ZM7.998 11.357c.995 0 1.81.805 1.81 1.81 0 1.004-.806 1.81-1.81 1.81a1.809 1.809 0 1 1 0-3.62Z'/%3E%3C/svg%3E");
  background-size: 10px;
}
.custom-toggle__check_lock:before {
  background: no-repeat center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 20'%3E%3Cpath d='M8 14.976a1.81 1.81 0 1 0 0-3.62 1.81 1.81 0 0 0 0 3.62Zm5.428-8.143a1.81 1.81 0 0 1 1.81 1.81v9.047a1.81 1.81 0 0 1-1.81 1.81H2.571a1.81 1.81 0 0 1-1.81-1.81V8.643c0-1.004.815-1.81 1.81-1.81h.905v-1.81a4.524 4.524 0 0 1 9.048 0v1.81h.904ZM8 2.31a2.714 2.714 0 0 0-2.714 2.714v1.81h5.428v-1.81A2.714 2.714 0 0 0 8 2.31Z'/%3E%3C/svg%3E");
  background-size: 8px;
}
.toggle-lock__svg {
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.custom-toggle__check_labeled {
  margin-right: 15px;
}
.custom-toggle__input {
  display: none;
}
.custom-toggle__input:checked + .custom-toggle__check {
  background-color: #017d80;
}
.custom-toggle__input:checked + .custom-toggle__check:after {
  opacity: 0;
}
.custom-toggle__input:checked + .custom-toggle__check:before {
  opacity: 1;
}
.custom-toggle__input:checked + .custom-toggle__check .custom-toggle__toggler {
  left: 17px;
}
.custom-toggle__input:disabled + .custom-toggle__check {
  cursor: default;
  opacity: 0.6;
}
.custom-toggle__input_sm:checked + .custom-toggle__check_sm .custom-toggle__toggler_sm {
  left: 13px;
}
.custom-toggle__input_lg:checked + .custom-toggle__check_lg .custom-toggle__toggler_lg {
  left: 20px;
}
.associative-list {
  padding: 15px 20px;
  border: 1px solid #ccd4d9;
}
.associative-list__title {
  float: left;
  color: #8597a6;
  text-transform: uppercase;
  margin-right: 20px;
}
.associative-list__title:not(:last-of-type) {
  padding-bottom: 15px;
}
.associative-list__desc {
  overflow: hidden;
}
.associative-list__desc:not(:last-of-type) {
  padding-bottom: 15px;
}
svg {
  width: 17px;
  height: 17px;
}
.icon_inherit {
  width: inherit;
  height: inherit;
}
.icon {
  width: 100%;
  height: 100%;
}
.icon_xxs {
  width: 10px;
  height: 10px;
}
.icon_xs {
  width: 13px;
  height: 13px;
}
.icon_s {
  width: 17px;
  height: 17px;
}
.icon_l {
  width: 20px;
  height: 20px;
}
.icon_xl {
  width: 32px;
  height: 32px;
}
.warning-icon-tooltip {
  padding-left: 5px;
  cursor: pointer;
}
.icon_rotate_90 {
  transform: rotate(90deg);
  margin-top: 16px;
}
.icon_top_margin_4 {
  margin-top: 4px;
}
.tooltip-inner {
  max-width: 400px;
  background-color: #002743;
}
.edit-list_border-left {
  border-left: 1px solid #ccd4d9;
}
.edit-list_sm.edit-list__large_indent {
  padding: 10px 0;
}
.edit-list_sm .edit-list__row {
  padding: 5px 12px;
}
.edit-list_sm .edit-list__row:not(:last-child) {
  border-bottom: none;
}
.edit-list_sm .edit-list__row_spec {
  padding: 5px;
}
.edit-list__row {
  padding: 15px;
}
.edit-list__row:not(.edit-list__row_text-row) {
  display: flex;
  align-items: flex-start;
}
.edit-list__row:nth-child(even) {
  background-color: #eeeff3;
}
.edit-list__row:not(:last-child) {
  border-bottom: 1px solid #ccd4d9;
}
.edit-list__title {
  flex-shrink: 0;
  padding: 5px 0;
  margin-right: 20px;
}
.edit-list__float-button {
  vertical-align: bottom;
  margin-left: 5px;
}
.edit-list__title_flex {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}
.edit-list__title_0 {
  margin-right: 0;
}
.edit-list__description {
  width: 100%;
  font-size: 12px;
}
.edit-list__description_with-error {
  display: flex;
  align-items: center;
}
.edit-list__description_with-error > *:not(:last-child) {
  margin-right: 15px;
}
.edit-list__value {
  width: 100%;
  align-self: center;
  word-break: break-word;
}
.word_break {
  word-wrap: break-word;
}
.edit-list__border {
  border: none;
}
.edit-list__title__fontWeight {
  font-weight: 600;
}
.details-list {
  margin-bottom: 20px;
}
.details-list_min {
  flex-basis: min-content;
}
.details-list_total {
  border-top: 1px solid #ccd4d9;
  padding-top: 20px;
}
.details-list_total .details-list__title,
.details-list_total .details-list__value {
  font-weight: bold;
}
.details-list__el {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
  position: relative;
  min-height: 31px;
}
.details-list__title,
.details-list__value {
  font-size: 14px;
  background-color: #ffffff;
}
.details-list__title {
  position: relative;
  font-weight: normal;
  overflow: hidden;
  width: 100%;
}
.details-list__title span {
  padding-right: 5px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}
.details-list__title:after {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  font-size: 10px;
  letter-spacing: 2px;
  content: '................................................................................................................................................................................................................................................................................................................................................................';
}
.details-list__value {
  padding-left: 5px;
  flex-shrink: 0;
}
.details-list__value_editable {
  position: relative;
}
.details-list__value_editable .field-edit__edit-btn {
  position: absolute;
  right: -30px;
}
.details-list__value_editable .field-edit__form {
  background-color: #ffffff;
  padding-left: 5px;
  position: absolute;
  width: 120px;
  bottom: -5px;
  right: -20px;
}
.details-title {
  font-size: 16px;
  font-weight: 600;
}
.details-description {
  color: #8597a6;
  margin-left: 0.5em;
}
.details-errors__block {
  display: flex;
  padding: 5px 12px;
}
.details-description__wrapper {
  align-self: center;
}
.details-errors__description {
  background-color: #fff;
  text-align: left;
  font-size: 12px;
  color: red;
  line-height: 20px;
}
.details-errors__icon {
  color: red;
  font-size: 14px;
  padding: 2px 10px 0 0;
  align-self: center;
}
.modal-content {
  box-shadow: none;
  background-color: transparent;
  border: none;
}
.modal-content__wrapper {
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 440px;
  margin: 0 auto;
}
.modal-content__500 {
  width: 500px;
}
.modal-content__wrapper_l {
  width: 720px;
}
.modal-content__wrapper.modal-content__1100 {
  width: 80vw;
  max-width: 1100px;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}
.modals-header {
  justify-content: space-between;
}
.modal-content__header {
  padding: 20px;
  font-weight: 600;
  border-bottom: 1px solid #ccd4d9;
}
.project-modal {
  border-bottom: 2px solid #e7eaec;
}
.modal-content__header-bold {
  font-weight: 700;
}
.modal-content__heading {
  font-weight: 600;
}
.modal-content__body {
  padding: 20px 10px 10px 20px;
}
.modal-content__body_without-form {
  padding: 20px;
}
.modal-content__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: -10px;
}
.modal-content__item {
  padding: 10px 0;
  width: calc(100% / 3 - 10px);
  margin-right: 10px;
  display: block;
}
.modal-content__item.modal-content__item_full-width {
  width: calc(100% - 10px);
  flex-grow: 1;
}
.modal-content__item.modal-content__item_half-width {
  width: calc(100% / 2 - 10px);
}
.modal-content__item.modal-content__item_two-third-width {
  width: calc(100% * 2 / 3 - 10px);
}
.modal-content__item.modal-content__item_one-third-width {
  width: calc(100% / 3 - 10px);
}
.modal-content__item.modal-content__action-input {
  width: 260px;
}
.modal-content__item.modal-content__item_grow {
  flex-grow: 1;
  width: auto;
}
.modal-content__item.modal-content__item_shrink {
  flex-shrink: 1;
  width: auto;
}
.modal-content__item.modal-content__item_flex-end {
  align-self: flex-end;
}
.modal-content__item.modal-content__item--no-padding {
  padding: 0;
}
.modal-content__label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 12px;
}
.modal-content__label_inline {
  margin-bottom: 0;
  font-size: 14px;
  margin-right: 20px;
}
.modal-content__footer {
  padding: 20px;
}
.modals-header__img {
  max-width: 100px;
  text-align: center;
  margin-left: 15px;
  flex-shrink: 0;
}
.modal-content__text {
  font-size: 12px;
}
.modal-content__message {
  word-wrap: normal;
  margin: 0 10px 0 0;
  display: block;
  white-space: pre-line;
}
.modal-content__message * {
  line-height: inherit;
}
.modal-content__message_mrgn:not(:last-child) {
  margin-bottom: 15px;
}
.modal-content__scrollable {
  height: 70vh;
  max-height: 660px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.stars-block {
  margin-top: 13px;
}
.popup-import-progress .progress {
  margin: 10px 0 0;
}
/* import popups*/
/* IMPORT POPUP */
.progress-bar__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
}
.popup__table-holder {
  height: auto;
  max-height: 460px;
  position: relative;
  overflow-y: auto;
  border: 1px solid #ccd4d9;
  border-top: none;
}
.popup__table-holder + .popup__table-holder {
  margin-top: -1px;
}
.popup__table-holder .table .import-error {
  width: 370px;
}
.popup__table-holder .table .save-error {
  width: 320px;
}
.form-error.standalone,
.form-warning.standalone {
  position: static;
  margin: 10px 0;
}
/* export popup*/
.export-progress {
  height: 25px;
  max-height: 25px;
  margin-bottom: 0;
}
/* disable closing by clicking out of modal*/
.modal-content__wrapper.disallow-close-outside:before {
  content: '';
  display: block;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: -10px;
  left: -10px;
  opacity: 0;
  clear: both;
  visibility: visible;
  z-index: -1;
}
@media (min-width: 768px) {
  .modal-content__wrapper.disallow-close-outside:before {
    position: fixed;
    top: -30px;
    left: calc(300px - 50vw);
  }
}
.searching-form {
  display: inline-flex;
  border: 1px solid #ccd4d9;
  border-radius: 4px;
  background-color: #ffffff;
}
.searching-form__btn {
  padding: 4px 10px;
  transition: 0.3s all;
  line-height: 1em;
  font-size: 14px;
}
.searching-form__btn:hover,
.searching-form__btn:focus {
  color: #017d80;
}
.doughnut-list {
  border-bottom: 1px solid #ccd4d9;
  display: flex;
  padding: 20px;
}
.doughnut-list__item {
  flex: 1;
  padding: 10px 0px;
}
.doughnut-list__title {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
}
.doughnut-list__subtitle {
  font-size: 12px;
  text-align: center;
}
.doughnut-list__subtitle_italic {
  font-style: italic;
}
.doughnut-list__chart-holder {
  max-width: 160px;
  margin: 0 auto 15px;
  position: relative;
}
.doughnut-list__amount {
  display: block;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
}
.image-gallery {
  display: flex;
}
.image-gallery.image-gallery_full {
  height: 100%;
}
.image-gallery__drop {
  border: 1px dashed #ccd4d9;
  color: #017d80;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  flex-shrink: 0;
  padding: 20px;
}
.image-gallery__drop.disabled {
  user-select: none;
  color: #ccd4d9;
  cursor: not-allowed;
}
.image-gallery__drop:not(.image-gallery__drop_full) {
  width: 200px;
  height: 200px;
}
.image-gallery__drop_full {
  width: 100%;
  height: 100%;
  outline-offset: -20px;
}
.image-gallery__drop_active {
  border: 1px solid #017d80;
}
.image-gallery__list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.image-gallery__item {
  position: relative;
  border-radius: 4px;
  flex-shrink: 0;
  line-height: 0;
}
.image-gallery__item:not(:last-child) {
  margin-right: 5px;
}
.image_placeholder-wrapper {
  display: flex;
}
.image-gallery__placeholder {
  height: 200px;
  width: 200px;
  min-width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-gallery__placeholder:not(:last-child) {
  margin-right: 5px;
}
.image-gallery__img {
  width: 200px;
  border: 1px solid #ccd4d9;
  cursor: pointer;
}
.image-gallery__btn-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
}
.image-gallery__btn {
  line-height: 1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-gallery__btn,
.image-gallery__btn:hover,
.image-gallery__btn:focus {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}
.image-gallery__btn-clickable:hover,
.image-gallery__btn-clickable:focus {
  background-color: rgba(1, 125, 128, 0.5);
  color: #ffffff;
}
.image-gallery__btn-icon {
  width: 26px;
  height: 26px;
  padding: 0;
}
.image-gallery__btn_active {
  background-color: #017d80;
  color: #ffffff;
}
.counter-holder {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 14px;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  width: calc(1.4em + 10px);
}
.counter-holder_bottom {
  top: inherit;
  bottom: 20px;
  transform: translate(-50%, 0);
}
.gallery-button-holder {
  background: rgba(0, 39, 67, 0.3);
  border-radius: 50%;
  color: #ffffff;
  margin: 20px auto;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.panel-collapsible {
  border: 1px solid #ccd4d9;
  border-radius: 10px;
  overflow: hidden;
}
.panel-collapsible__header {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.panel-collapsible__header_padd-0 {
  padding: 0;
}
.panel-collapsible__header-dark {
  background-color: #002743;
  color: #ffffff;
}
.panel-collapsible__footer {
  border-top: 1px solid #ccd4d9;
}
.collapsible-title_el {
  display: flex;
  flex-flow: row nowrap;
  align-self: normal;
  transition: opacity 0.2s ease-in-out;
  padding: 0 10px;
}
.el_inner-wrapper {
  padding: 10px;
  align-self: center;
}
.el_inner-wrapper_column {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.right-el {
  margin-left: auto;
  justify-content: flex-end;
}
.title_el--details {
  display: block;
  font-size: 12px;
}
.toggle__wrapper:not(.navigation__item_open) .animated-height {
  clip: rect(0 0 0 0);
  width: 100%;
  overflow: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}
.animated-height {
  transition: height 0.3s ease-out, padding 0.3s ease-out;
  transform-origin: top;
  overflow: hidden;
}
.text-items--list-wrapper {
  font-size: 12px;
  margin: 10px;
  padding: 5px 10px;
  width: calc(20% - 20px);
}
@media all and (max-width: 1200px) {
  .text-items--list-wrapper {
    width: calc(33% - 20px);
  }
}
@media all and (max-width: 900px) {
  .text-items--list-wrapper {
    width: calc(100% - 20px);
  }
}
.text-items--list + .text-items--list {
  margin-top: 20px;
}
.text-items--header {
  margin: 10px 0;
}
.text-items--item {
  margin: 0;
  padding: 3px 0;
}
.bullet-items--item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bullet-items--item:before {
  content: '●';
  margin: 0 8px 0 1.2em;
}
.block_bullet-wrapper {
  display: flex;
}
.no-items {
  color: #002743;
  font-size: 12px;
  font-style: italic;
}
.secondary-font__title {
  font-size: 12px;
  font-weight: 600;
}
/* CROP TOOL */
.ng2-imgcrop input {
  display: none;
}
.crop-tool .crop-canvas {
  margin-bottom: 10px;
  display: block;
}
.crop-tool .crop-buttons {
  margin: 10px 20px 20px;
}
.crop-tool .crop-buttons button:first-child {
  margin-right: 5px;
}
.crop-tool .crop-browse {
  margin-top: 10px;
}
.crop-tool.inline {
  display: inline-block;
}
.crop-tool.inline .crop-browse {
  margin-right: 3px;
  display: inline-block;
}
.crop-tool .file-input-wrapper input {
  display: none !important;
}
.crop-canvas canvas {
  height: auto;
  width: auto;
  max-width: 100%;
  display: block;
  margin: auto;
}
.cropper__label {
  width: auto;
  max-width: 200px;
  font-size: 14px;
}
.cropper__label-grey {
  font-size: 12px;
  color: #bfbfbf;
}
.progress {
  position: relative;
  overflow: hidden;
  height: 25px;
  background: #b7b7b7;
  box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  border-radius: 4px;
}
.progress-bar {
  color: #ffffff;
  background-image: url('./smartadmin/img/pattern/overlay-pattern.png');
}
.progress-bar-success {
  background-color: #017d80 !important;
}
.progress-striped .progress-bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.progress.active .progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-danger {
  background-color: #a90329 !important;
}
.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
  background-color: #c79121 !important;
}
.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
  background-color: #57889c !important;
}
.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.snotify {
  width: 400px;
}
.snotifyToast__title {
  font-size: 22px;
}
.snotifyToast__buttons button {
  font-size: 18px;
}
.snotifyToast__body {
  width: 100%;
}
.panel-row {
  padding: 15px;
  font-size: 12px;
  min-height: 60px;
}
.panel-row:nth-child(even) {
  background-color: #eeeff3;
}
.panel-row__narrow {
  padding: 5px 10px;
}
.panel-row__narrow:nth-child(even) {
  background-color: #eeeff3;
}
.cat-col {
  padding: 0 10px;
  vertical-align: middle;
}
.cat-col-min {
  width: auto;
  flex-shrink: 1;
}
.cat-col-fixed-80 {
  width: 80px;
  min-width: 80px;
}
.cat-col-fixed-45 {
  width: 45px;
  min-width: 45px;
}
.cat-col-max {
  width: auto;
  flex-grow: 1;
}
.cat-col-1 {
  width: 6.25%;
}
.cat-col-2 {
  width: 12.5%;
}
.panel-tag {
  padding: 1px 6px;
  border-radius: 4px;
  margin: 5px 5px 5px 0;
}
.attribute-input {
  width: auto;
  padding: 0;
  border: none !important;
  color: inherit !important;
  background-color: inherit !important;
  font-size: 10px;
}
.attribute-input:hover {
  background-color: transparent;
  color: inherit;
}
.attribute-input:focus {
  background-color: transparent;
  color: inherit;
}
.attribute-input.state-error {
  background: rgba(255, 8, 8, 0.1);
  border-color: #a90329;
}
.form-error {
  font-size: 12px;
  position: absolute;
}
.colorpicker {
  padding: 6px;
  border-radius: 40px;
  border: 1px solid #ccd4d9;
  width: 60px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.colorpicker__picker {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* --Calculations*/
.budgeting .tip {
  color: #3276b1;
  margin: 6px 0;
}
.fhi {
  height: 70px;
}
.fhi:not(:last-child) {
  border-right: 1px solid #ccd4d9;
}
.positive {
  color: #017d80;
  outline: 1px solid #b5c2b5;
  background-color: #cddacd;
}
.negative {
  color: #b40909;
  outline: 1px solid #c63838;
  background-color: #ffe5e5;
}
.btn-group.no-margin {
  margin-bottom: 0;
}
.btn-group.no-margin > label {
  margin-bottom: 0;
}
/* --Chart Controls */
.period-group {
  margin-bottom: 0;
}
.budgeting-loader {
  padding: 25px;
  text-align: center;
}
/*.budgeting-loader .fa-spin{*/
/*  margin-top: 10px;*/
/*}*/
/* --Overall Line Chart */
.chart {
  width: auto;
  height: 320px;
  margin-right: 10px;
  position: relative;
}
.chart canvas {
  width: 100% !important;
  height: 100% !important;
}
/* --Risk Bar Chart */
.chart.risk {
  width: auto !important;
}
.chart.risk canvas {
  margin-left: -1px;
}
/* --Building Bar Chart With Scrolling */
.chart-stretchable-holder {
  width: auto;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.chart-stretchable {
  height: 300px;
  width: 100%;
}
.chart-stretchable canvas {
  height: 300px;
  width: 100%;
  margin-left: -15px;
}
/* --Second Y-Axis Chart */
.chart-axis {
  height: 275px;
  width: 100%;
  position: absolute;
  left: 0;
  pointer-events: none;
}
.toolbar-panel__inner {
  margin: 20px;
}
.toolbar-panel__inner-bordered {
  margin: 10px;
  border: 1px solid #ccc;
}
/* Locations*/
.location__total {
  font-size: 18px;
  font-weight: 600;
}
.location__total-wrapper {
  width: 20vw;
  align-self: stretch;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ccc;
}
@media all and (min-width: 1024px) {
  .location__total-wrapper {
    width: 212px;
  }
}
.tooltip-placement {
  position: relative;
}
.list-in-row {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.list-in-row__five {
  width: calc((100% - 40px) / 5);
  margin-bottom: 10px;
}
.list-in-row__five:not(:nth-child(5n)) {
  margin-right: 10px;
}
.list-in-row__four {
  width: calc((100% - 30px) / 4);
  margin-bottom: 10px;
}
.list-in-row__four:not(:nth-child(4n)) {
  margin-right: 10px;
}
.xs-3 {
  width: 3%;
}
.xs-12 {
  width: 12%;
}
.xs-16 {
  width: 16%;
}
.xs-24 {
  width: 24%;
}
.xs-25 {
  width: 25%;
}
.xs-32 {
  width: 32%;
}
.xs-33 {
  width: 33.333%;
}
.xs-40 {
  width: 40%;
}
.xs-47 {
  width: 47%;
}
.xs-49 {
  width: 49%;
}
.xs-50 {
  width: 50%;
}
.xs-58 {
  width: 58%;
}
.xs-65 {
  width: 65%;
}
.xs-74 {
  width: 74%;
}
.xs-75 {
  width: 75%;
}
.xs-82 {
  width: 82%;
}
.xs-100 {
  width: 100%;
}
.hide-xs {
  display: none !important;
}
.show-xs {
  display: block !important;
}
@media all and (min-width: 768px) {
  .sm-16 {
    width: 16%;
  }
  .sm-24 {
    width: 24%;
  }
  .sm-30 {
    width: 30%;
  }
  .sm-32 {
    width: 32%;
  }
  .sm-40 {
    width: 40%;
  }
  .sm-49 {
    width: 49%;
  }
  .sm-58 {
    width: 58%;
  }
  .sm-65 {
    width: 65%;
  }
  .sm-74 {
    width: 74%;
  }
  .sm-82 {
    width: 82%;
  }
  .sm-100 {
    width: 100%;
  }
  .hide-xs {
    display: block !important;
  }
  .show-xs {
    display: none !important;
  }
}
@media all and (min-width: 992px) {
  .md-16 {
    width: 16%;
  }
  .md-24 {
    width: 24%;
  }
  .md-32 {
    width: 32%;
  }
  .md-40 {
    width: 40%;
  }
  .md-49 {
    width: 49%;
  }
  .md-58 {
    width: 58%;
  }
  .md-65 {
    width: 65%;
  }
  .md-74 {
    width: 74%;
  }
}
@media all and (min-width: 1920px) {
  .lg-16 {
    width: 16%;
  }
  .lg-32 {
    width: 32%;
  }
  .lg-47 {
    width: 47%;
  }
  .lg-49 {
    width: 49%;
  }
  .lg-82 {
    width: 82%;
  }
}
.el-in-a-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.el-in-a-row > *:not(:last-child) {
  margin-right: 5px;
}
.el-in-a-row_lg > *:not(:last-child) {
  margin-right: 15px;
}
.el-in-a-row_xl > *:not(:last-child) {
  margin-right: 20px;
}
.el-in-a-row_md > *:not(:last-child) {
  margin-right: 10px;
}
.el-in-a-row_c {
  align-items: center;
}
.el-in-a-row_s {
  align-items: start;
}
.el-in-a-row_nowrap {
  flex-wrap: nowrap;
}
.el-in-a-row__item_separated,
.el-in-a-row_separated > * {
  position: relative;
}
.el-in-a-row__item_separated:not(:last-child):after,
.el-in-a-row_separated > *:not(:last-child):after {
  content: '';
  display: block;
  height: 14px;
  width: 3px;
  background-color: #ccd4d9;
  border-radius: 10px;
  right: -12px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.el-in-a-row__item_separated_sm:not(:last-child):after {
  width: 1px;
}
.el-in-a-row__item_separated:not(:last-child) {
  margin-right: 20px !important;
}
.el-in-a-row_static-width {
  width: 350px;
  min-width: 350px;
}
.el-block {
  display: block;
}
.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-a-start {
  align-items: flex-start;
}
.flex-a-stretch {
  align-items: stretch;
}
.flex-a-center {
  align-items: center;
}
.flex-a-end {
  align-items: flex-end;
}
.flex_to-start {
  justify-content: flex-start;
}
.flex_to-end {
  justify-content: flex-end;
}
.flex_reverse {
  flex-direction: row-reverse;
}
.flex_to-start {
  justify-content: flex-start;
}
.flex_j-center {
  justify-content: center;
}
.flex-column__full-height {
  height: 100%;
}
.flex_nowrap {
  display: flex;
  flex-wrap: nowrap;
}
.flex__nowrap {
  flex-wrap: nowrap;
}
.flex-grow {
  flex-grow: 1;
}
.flex_reverse {
  flex-direction: row-reverse;
}
.overflow {
  overflow: hidden;
}
.content {
  border: 1px solid #ccd4d9;
  border-radius: 10px;
  background-color: #ffffff;
}
.content-row {
  padding: 20px;
  border-bottom: 1px solid #ccd4d9;
}
.content-details {
  padding: 20px 40px;
}
.content-title {
  font-size: 24px;
  font-weight: 600;
}
.content-subtitle {
  letter-spacing: 0.5px;
}
.content-remark {
  font-size: 12px;
  color: #ccd4d9;
  letter-spacing: 0.5px;
  font-weight: 400;
}
.content-gallery {
  padding: 15px 0 0;
  text-align: center;
}
.content-details_sm {
  padding: 10px;
}
.content-loading {
  text-align: center;
}
.text-small {
  font-size: 12px;
}
.font-weight-medium {
  font-weight: 500;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-t-5 {
  padding-top: 5px;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-t {
  padding-top: 20px;
}
.padding-b {
  padding-bottom: 20px;
}
.content-info {
  font-size: 12px;
  font-style: italic;
}
.content-footer {
  padding: 5px 12px;
  border-top: 1px solid #ccd4d9;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-not-cropped {
  white-space: nowrap;
}
.text-cropped {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-cropped__description {
  width: calc(100% - 20px);
}
.text-cropped__btn {
  margin-left: 5px;
}
.text-cropped__80 {
  width: auto;
  max-width: 80%;
  display: inline-block;
  vertical-align: inherit;
  line-height: inherit;
}
.text-weight__normal {
  font-weight: normal;
}
.mrgn-auto-h {
  margin: 0 auto;
}
.mrgn-l {
  margin-left: 25px;
}
.mrgn-l-xs {
  margin-left: 5px;
}
.mrgn-l__62 {
  margin-left: 62px;
}
.mrgn-r {
  margin-right: 20px;
}
.mrgn-r--26 {
  margin-right: 26px;
}
.mrgn-b {
  margin-bottom: 20px;
}
.mrgn-b_sm {
  margin-bottom: 10px !important;
}
.mrgn-b_xs {
  margin-bottom: 5px;
}
.mrgn-t {
  margin-top: 20px;
}
.mrgn-l_12 {
  margin-left: 12px;
}
.mrgn-t_sm {
  margin-top: 10px;
}
.mrgn-t_xs {
  margin-top: 5px;
}
.border-t {
  border-top: 1px solid #ccd4d9;
}
.border-n {
  border: none;
}
.body-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  min-height: 100%;
}
.global-body {
  padding-bottom: 185px;
  overflow: hidden;
  min-width: calc(100% - 220px);
  width: 100%;
}
.position-relative {
  position: relative;
}
.fixed-width {
  flex-shrink: 0;
}
.fixed-width-400 {
  width: 400px;
}
.fixed-width-300 {
  width: 300px;
}
.fixed-width-150 {
  width: 150px;
}
.fixed-width-80 {
  min-width: 80px;
}
.fixed-width-40 {
  min-width: 40px;
}
.faded {
  opacity: 0.4;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}
.width-fit-content {
  width: fit-content;
}
.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: auto !important;
  overflow: hidden;
}
.visually-hidden__vertical {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  width: 0 !important;
  overflow: hidden;
}
.border-tl-tr {
  border-radius: 10px 10px 0 0;
}
.border-bl-br {
  border-radius: 0 0 10px 10px;
}
.border-bl {
  border-radius: 0 0 10px 0;
}
.pendo-notification-bubble-unread-count,
._pendo-home-view-bubble > div {
  line-height: 0;
}
.font_sec {
  font-size: 12px;
}
.invisible {
  position: absolute;
  visibility: hidden;
}
.tip {
  color: #017d80;
  font-style: italic;
  font-size: 12px;
}
.subtitle {
  font-size: 10px;
}
.subtitle_transparency {
  opacity: 0.5;
}
.title {
  font-size: 14px;
}
.title_xs {
  font-size: 12px;
}
.title_reg {
  font-size: 14px;
}
.title_md {
  font-size: 18px;
  font-weight: 300;
}
.title_sm {
  font-size: 16px;
}
.title_lg {
  font-size: 24px;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-bold {
  font-weight: 700;
}
.text-semibold {
  font-weight: 600;
}
.text-light {
  font-weight: 300;
}
.icon-label > * {
  display: inline;
  vertical-align: middle;
}
.icon-label > *:not(:last-child) {
  margin-right: 5px;
}
.text-break-words {
  word-break: break-word;
}
.text__green {
  color: #008134;
}
.text__red {
  color: #b40909;
}
.text__grey {
  color: #9ba3a7;
}
.text__italic {
  font-style: italic;
}
[hidden] {
  display: none !important;
}
.tooltip {
  z-index: 1060 !important;
}
.tooltip div {
  word-break: break-word;
  white-space: pre-line;
  text-align: left;
}
.cdk-overlay-container {
  z-index: 1060;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  width: initial;
  border: none;
  overflow: hidden;
}
.mat-form-field-appearance-legacy .mat-form-field-flex {
  align-items: center;
}
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 18px !important;
}
.mat-form-field-appearance-legacy .mat-form-field-label-wrapper {
  padding-top: 0;
  top: 0;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  left: 12px;
  top: 7px;
  font-size: 12px;
  color: #ccd4d9;
  font-family: 'Roboto', sans-serif;
}
.mat-form-field-appearance-legacy .mat-form-field-label .mat-placeholder-required {
  color: inherit !important;
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-label {
  color: #ccd4d9;
  opacity: 0.6;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none;
}
input.mat-input-element {
  padding: 6px 12px;
  margin: 0;
  background-color: #ffffff;
}
app-svg {
  font-size: 0;
}
.mat-form-field-suffix .mat-icon-button {
  width: 32px;
  height: 30px;
  background-color: #f9f9f9;
  border-left: 1px solid #ccd4d9;
  border-radius: 0;
}
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: initial;
  width: initial !important;
}
.mat-form-field-appearance-legacy .mat-form-field-suffix .datepicker__custom-icon-wrapper .mat-icon-button .mat-icon {
  font-size: 16px;
}
.cdk-virtual-scroll-viewport {
  /* This will affect the scrollbar globally */
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the tracking area */
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: #888;
  /* color of the scroll thumb */
  border-radius: 10px;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* color of the scroll thumb on hover */
}
.snotify {
  width: 460px;
}
.snotifyToast {
  border-radius: 10px;
}
.snotifyToast__body {
  font-size: 14px;
}
.snotifyToast__title {
  font-size: 16px;
  margin-bottom: 10px;
}
.snotify-error .snotifyToast__inner,
.snotify-success .snotifyToast__inner,
.snotify-warning .snotifyToast__inner {
  flex-flow: row-reverse;
  min-height: initial;
  padding: 10px 20px;
  align-items: center;
}
.snotify-error .snotifyToast__body,
.snotify-success .snotifyToast__body,
.snotify-warning .snotifyToast__body {
  padding: 7px 0;
  font-weight: 500;
}
.snotify-error .snotify-icon--success,
.snotify-success .snotify-icon--success,
.snotify-warning .snotify-icon--success {
  background: no-repeat center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 10' enable-background='new 0 0 14 10'%3E%3Cpath fill='%23fff' d='M13.715.307a1.035 1.035 0 0 1-.016 1.443L5.867 9.564a1.479 1.479 0 0 1-2.097 0L.3 6.104A1.035 1.035 0 0 1 .286 4.66a.986.986 0 0 1 1.414-.016l3.12 3.112L12.3.291a.986.986 0 0 1 1.414.016Z'/%3E%3C/svg%3E");
}
.snotify-error .snotify-icon--warning,
.snotify-success .snotify-icon--warning,
.snotify-warning .snotify-icon--warning,
.snotify-error .snotify-icon--error,
.snotify-success .snotify-icon--error,
.snotify-warning .snotify-icon--error {
  background: no-repeat center 40% url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14' enable-background='new 0 0 16 14'%3E%3Cpath fill='%23fff' d='M15.848 12.41 8.998.564A1.157 1.157 0 0 0 8 0a1.157 1.157 0 0 0-.998.564L.151 12.41c-.207.353-.201.707.019 1.06.1.163.237.292.41.387.178.095.368.143.57.143h13.7c.202 0 .389-.048.561-.143.179-.095.318-.224.42-.387.22-.353.225-.707.017-1.06ZM7 4h2v5H7V4Zm0 6h2v2H7v-2Z'/%3E%3C/svg%3E");
}
.snotify-error .snotify-icon,
.snotify-success .snotify-icon,
.snotify-warning .snotify-icon {
  background-size: 50%;
  width: 35px;
  height: 35px;
  position: relative;
  right: initial;
  top: initial;
  line-height: initial;
  transform: none;
  max-height: inherit;
  max-width: inherit;
  border-radius: 50%;
  background-color: black;
  flex-shrink: 0;
  margin-right: 12px;
}
.snotify-error {
  background-color: #fef1f1;
  border: 1px solid #e50b0b;
}
.snotify-error .snotifyToast__body {
  color: #830707;
}
.snotify-error .snotify-icon {
  background-color: #b40909;
}
.snotify-success {
  background-color: #e6f9f2;
  border: 1px solid #29b783;
}
.snotify-success .snotifyToast__body {
  color: #176347;
}
.snotify-success .snotify-icon {
  background-color: #208d65;
}
.snotify-warning {
  background-color: #ffffff;
  border: 1px solid #ecc75f;
}
.snotify-warning .snotifyToast__body {
  color: #cd9d18;
}
.snotify-warning .snotify-icon {
  background-color: #e7b731;
}
.snotify-confirm {
  background: #002743;
}
.snotifyToast__buttons {
  justify-content: end;
  padding: 10px 15px;
}
.snotifyToast__buttons button {
  width: initial;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 7px 14px;
  font-size: 12px;
  border: 1px #ffffff;
  border-style: solid !important;
}
.snotifyToast__buttons button:not(:first-child) {
  margin-left: 10px;
}
.snotifyToast__buttons button:last-child {
  color: #002743;
  background-color: #48fe9b;
  border-color: #48fe9b;
}
