@import '_variables';

.panel-row {
  padding: 15px;
  font-size: @secondary-font-size;
  min-height: 60px;

  &:nth-child(even) {
    background-color: @soft-peach;
  }
}

.panel-row__narrow {
  padding: 5px 10px;
  &:nth-child(even) {
    background-color: @soft-peach;
  }
}

.cat-col {
  padding: 0 10px;
  vertical-align: middle;
}

.cat-col-min {
  width: auto;
  flex-shrink: 1;
}

.cat-col-fixed-80 {
  width: 80px;
  min-width: 80px;
}

.cat-col-fixed-45 {
  width: 45px;
  min-width: 45px;
}

.cat-col-max {
  width: auto;
  flex-grow: 1;
}

.cat-col-1 {
  width: 6.25%;
}

.cat-col-2 {
  width: 12.5%;
}

.panel-tag {
  padding: 1px 6px;
  border-radius: @border-radius;
  margin: 5px 5px 5px 0;
}

.attribute-input {
  width: auto;
  padding: 0;
  border: none !important;
  color: inherit !important;
  background-color: inherit !important;
  font-size: 10px;

  &:hover {
    background-color: transparent;
    color: inherit;
  }

  &:focus {
    background-color: transparent;
    color: inherit;
  }

  &.state-error {
    background: rgba(255, 8, 8, 0.1);
    border-color: #a90329;
  }
}

.form-error {
  font-size: 12px;
  position: absolute;
}

.colorpicker {
  padding: 6px;
  border-radius: 40px;
  border: @border;
  width: 60px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.colorpicker__picker {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
