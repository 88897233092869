.list-in-row {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.list-in-row__five {
  width: calc((100% - 40px) / 5);
  margin-bottom: 10px;

  &:not(:nth-child(5n)) {
    margin-right: 10px;
  }
}

.list-in-row__four {
  width: calc((100% - 30px) / 4);
  margin-bottom: 10px;

  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
}

.xs-3 {
  width: 3%;
}
.xs-12 {
  width: 12%;
}
.xs-16 {
  width: 16%;
}
.xs-24 {
  width: 24%;
}
.xs-25 {
  width: 25%;
}
.xs-32 {
  width: 32%;
}
.xs-33 {
  width: 33.333%;
}
.xs-40 {
  width: 40%;
}
.xs-47 {
  width: 47%;
}
.xs-49 {
  width: 49%;
}
.xs-50 {
  width: 50%;
}
.xs-58 {
  width: 58%;
}
.xs-65 {
  width: 65%;
}
.xs-74 {
  width: 74%;
}
.xs-75 {
  width: 75%;
}
.xs-82 {
  width: 82%;
}
.xs-100 {
  width: 100%;
}
.hide-xs {
  display: none !important;
}
.show-xs {
  display: block !important;
}

@media all and (min-width: 768px) {
  .sm-16 {
    width: 16%;
  }
  .sm-24 {
    width: 24%;
  }
  .sm-30 {
    width: 30%;
  }
  .sm-32 {
    width: 32%;
  }
  .sm-40 {
    width: 40%;
  }
  .sm-49 {
    width: 49%;
  }
  .sm-58 {
    width: 58%;
  }
  .sm-65 {
    width: 65%;
  }
  .sm-74 {
    width: 74%;
  }
  .sm-82 {
    width: 82%;
  }
  .sm-100 {
    width: 100%;
  }

  .hide-xs {
    display: block !important;
  }
  .show-xs {
    display: none !important;
  }
}

@media all and (min-width: 992px) {
  .md-16 {
    width: 16%;
  }
  .md-24 {
    width: 24%;
  }
  .md-32 {
    width: 32%;
  }
  .md-40 {
    width: 40%;
  }
  .md-49 {
    width: 49%;
  }
  .md-58 {
    width: 58%;
  }
  .md-65 {
    width: 65%;
  }
  .md-74 {
    width: 74%;
  }
}

@media all and (min-width: 1920px) {
  .lg-16 {
    width: 16%;
  }
  .lg-32 {
    width: 32%;
  }
  .lg-47 {
    width: 47%;
  }
  .lg-49 {
    width: 49%;
  }
  .lg-82 {
    width: 82%;
  }
}
