@import '_variables';

.details-list {
  margin-bottom: 20px;
}

.details-list_min {
  flex-basis: min-content;
}

.details-list_total {
  border-top: @border;
  padding-top: 20px;

  .details-list__title,
  .details-list__value {
    font-weight: bold;
  }
}

.details-list__el {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
  position: relative;
  min-height: 31px;
}

.details-list__title,
.details-list__value {
  font-size: @primary-font-size;
  background-color: @white;
}

.details-list__title {
  position: relative;
  font-weight: normal;
  overflow: hidden;
  width: 100%;

  span {
    padding-right: 5px;
    background-color: @white;
    position: relative;
    z-index: 1;
  }

  &:after {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    font-size: 10px;
    letter-spacing: 2px;
    content: '................................................................................................................................................................................................................................................................................................................................................................';
  }
}

.details-list__value {
  padding-left: 5px;
  flex-shrink: 0;
}

.details-list__value_editable {
  position: relative;

  .field-edit__edit-btn {
    position: absolute;
    right: -30px;
  }

  .field-edit__form {
    background-color: @white;
    padding-left: 5px;
    position: absolute;
    width: 120px;
    bottom: -5px;
    right: -20px;
  }
}

.details-title {
  font-size: 16px;
  font-weight: 600;
}

.details-description {
  color: @regent-grey;
  margin-left: 0.5em;
}
.details-errors__block {
  display: flex;
  padding: 5px 12px;
}

.details-description__wrapper {
  align-self: center;
}
.details-errors__description {
  background-color: #fff;
  text-align: left;
  font-size: @secondary-font-size;
  color: red;
  line-height: 20px;
}

.details-errors__icon {
  color: red;
  font-size: @primary-font-size;
  padding: 2px 10px 0 0;
  align-self: center;
}
