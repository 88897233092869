.filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;

  &:not(.filter__unbordered) {
    border-top: @border;
  }
  &:not(.filter__no-padding) {
    padding: 10px 0 10px 10px;
  }
  &.filter__no_padding-top {
    padding-top: 0;
  }
  label {
    position: relative;
  }
}

.filter__no-padding {
  margin-right: -10px;
}

.filter__dropdown {
  width: calc((100% - 60px) / 6);
  margin-bottom: 10px;
  min-width: 197.5px;
  margin-right: 10px;
  position: relative;

  &.filter__not_bordered {
    border-top: none;
    padding-top: 0;
  }
  .filter__dropdown {
    margin-bottom: 10px;
  }
  &.filter__dropdown-limited-w {
    width: 197.5px;
  }
}

.filter__dropdown_no-mrgn {
  margin-bottom: 0;
}

.filter__extended-filter {
  margin: 0 10px 10px 0;
  display: inline-flex;
}

.filter__extended-label {
  padding-top: 7px;
  padding-bottom: 7px;
}

.filter__bordered-bottom {
  border-bottom: @border;
}

.filter-label {
  font-size: @secondary-font-size;
}
