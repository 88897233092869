@import '_variables';

.modal-content {
  box-shadow: none;
  background-color: transparent;
  border: none;
}

.modal-content__wrapper {
  background: @white;
  box-shadow: 0 4px 20px rgba(@black, 0.25);
  border-radius: 10px;
  width: 440px;
  margin: 0 auto;
}

.modal-content__500 {
  width: 500px;
}

.modal-content__wrapper_l {
  width: 720px;
}

.modal-content__wrapper.modal-content__1100 {
  width: 80vw;
  max-width: 1100px;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.modals-header {
  justify-content: space-between;
}

.modal-content__header {
  padding: 20px;
  font-weight: 600;
  border-bottom: @border;
}

&.project-modal {
  border-bottom: 2px solid #e7eaec;
}

.modal-content__header-bold {
  font-weight: 700;
}

.modal-content__heading {
  font-weight: 600;
}

.modal-content__body {
  padding: 20px 10px 10px 20px;
}

.modal-content__body_without-form {
  padding: 20px;
}

.modal-content__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: -10px;
}

.modal-content__item {
  padding: 10px 0;
  width: calc(100% / 3 - 10px);
  margin-right: 10px;
  display: block;

  &.modal-content__item_full-width {
    width: calc(100% - 10px);
    flex-grow: 1;
  }

  &.modal-content__item_half-width {
    width: calc(100% / 2 - 10px);
  }

  &.modal-content__item_two-third-width {
    width: calc(100% * 2 / 3 - 10px);
  }

  &.modal-content__item_one-third-width {
    width: calc(100% / 3 - 10px);
  }

  &.modal-content__action-input {
    width: 260px;
  }

  &.modal-content__item_grow {
    flex-grow: 1;
    width: auto;
  }

  &.modal-content__item_shrink {
    flex-shrink: 1;
    width: auto;
  }

  &.modal-content__item_flex-end {
    align-self: flex-end;
  }

  &.modal-content__item--no-padding {
    padding: 0;
  }
}

.modal-content__label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: @secondary-font-size;
}

.modal-content__label_inline {
  margin-bottom: 0;
  font-size: @primary-font-size;
  margin-right: 20px;
}

.modal-content__footer {
  padding: 20px;
}

.modals-header__img {
  max-width: 100px;
  text-align: center;
  margin-left: 15px;
  flex-shrink: 0;
}

.modal-content__text {
  font-size: @secondary-font-size;
}

.modal-content__message {
  word-wrap: normal;
  margin: 0 10px 0 0;
  display: block;
  white-space: pre-line;

  * {
    line-height: inherit;
  }
}

.modal-content__message_mrgn {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.modal-content__scrollable {
  height: 70vh;
  max-height: 660px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.stars-block {
  margin-top: 13px;
}

.popup-import-progress .progress {
  margin: 10px 0 0;
}

/* import popups*/

/* IMPORT POPUP */

.progress-bar__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
}

.popup__table-holder {
  height: auto;
  max-height: 460px;
  position: relative;
  overflow-y: auto;
  border: @border;
  border-top: none;

  & + .popup__table-holder {
    margin-top: -1px;
  }

  .table .import-error {
    width: 370px;
  }

  .table .save-error {
    width: 320px;
  }
}
.form-error,
.form-warning {
  &.standalone {
    position: static;
    margin: 10px 0;
  }
}

/* export popup*/

.export-progress {
  height: 25px;
  max-height: 25px;
  margin-bottom: 0;
}

/* disable closing by clicking out of modal*/

.modal-content__wrapper.disallow-close-outside:before {
  content: '';
  display: block;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: -10px;
  left: -10px;
  opacity: 0;
  clear: both;
  visibility: visible;
  z-index: -1;
  @media (min-width: 768px) {
    position: fixed;
    top: -30px;
    left: calc(300px - 50vw);
  }
}
