svg {
  width: 17px;
  height: 17px;
}

.icon_inherit {
  width: inherit;
  height: inherit;
}

//app-svg {
//  font-size: 0;
//}

.icon {
  width: 100%;
  height: 100%;
}

.icon_xxs {
  width: 10px;
  height: 10px;
}

.icon_xs {
  width: 13px;
  height: 13px;
}

.icon_s {
  width: 17px;
  height: 17px;
}

.icon_l {
  width: 20px;
  height: 20px;
}

.icon_xl{
  width: 32px;
  height: 32px;
}

.warning-icon-tooltip {
  padding-left: 5px;
  cursor: pointer;
}

.icon_rotate_90 {
  transform: rotate(90deg);
  margin-top: 16px;
}

.icon_top_margin_4 {
  margin-top: 4px;
}
