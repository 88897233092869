@import '_variables';

.header {
  font-size: @secondary-font-size;
  padding: 20px;

  &:not(.header_not-bordered) {
    border-bottom: @border;
  }
}

.header_transparent {
  padding: 20px 0;
}

.header__title {
  font-size: 20px;
  word-wrap: break-word;
  color: @blue-whale;

  a {
    color: inherit;
    font-size: inherit;
  }
  letter-spacing: normal;
}
