@import '_variables';

//todo get rid of flex-wrap: wrap; align-items: center;
.el-in-a-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-right: 5px;
  }
}

.el-in-a-row_lg {
  > *:not(:last-child) {
    margin-right: 15px;
  }
}

.el-in-a-row_xl {
  > *:not(:last-child) {
    margin-right: 20px;
  }
}

.el-in-a-row_md {
  > *:not(:last-child) {
    margin-right: 10px;
  }
}

.el-in-a-row_c {
  align-items: center;
}

.el-in-a-row_s {
  align-items: start;
}

.el-in-a-row_nowrap {
  flex-wrap: nowrap;
}

.el-in-a-row__item_separated,
.el-in-a-row_separated > * {
  position: relative;

  &:not(:last-child):after {
    content: '';
    display: block;
    height: 14px;
    width: 3px;
    background-color: @grey-goose;
    border-radius: 10px;
    right: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}

.el-in-a-row__item_separated_sm:not(:last-child):after {
  width: 1px;
}

.el-in-a-row__item_separated&:not(:last-child) {
  margin-right: 20px !important;
}

.el-in-a-row_static-width {
  width: 350px;
  min-width: 350px;
}

.el-block {
  display: block;
}

.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-a-start {
  align-items: flex-start;
}

.flex-a-stretch {
  align-items: stretch;
}

.flex-a-center {
  align-items: center;
}

.flex-a-end {
  align-items: flex-end;
}

.flex_to-start {
  justify-content: flex-start;
}

.flex_to-end {
  justify-content: flex-end;
}

.flex_reverse {
  flex-direction: row-reverse;
}

.flex_to-start {
  justify-content: flex-start;
}

.flex_j-center {
  justify-content: center;
}

.flex-column__full-height {
  height: 100%;
}

.flex_nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex__nowrap {
  flex-wrap: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex_reverse {
  flex-direction: row-reverse;
}

.overflow {
  overflow: hidden;
}

.content {
  border: @border;
  border-radius: 10px;
  background-color: @white;
}

.content-row {
  padding: 20px;
  border-bottom: @border;
}

.content-details {
  padding: 20px 40px;
}

.content-title {
  font-size: 24px;
  font-weight: 600;
}

.content-subtitle {
  letter-spacing: 0.5px;
}

.content-remark {
  font-size: 12px;
  color: @grey-goose;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.content-gallery {
  //padding: 45px 0 15px;
  padding: 15px 0 0;
  text-align: center;
}

.content-details_sm {
  padding: 10px;
}

.content-loading {
  text-align: center;
}

.text-small {
  font-size: @secondary-font-size;
}

.font-weight-medium {
  font-weight: 500;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-t-5 {
  padding-top: 5px;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-t {
  padding-top: 20px;
}

.padding-b {
  padding-bottom: 20px;
}

.content-info {
  font-size: @secondary-font-size;
  font-style: italic;
}

.content-footer {
  padding: 5px 12px;
  border-top: @border;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-not-cropped {
  white-space: nowrap;
}

.text-cropped {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-cropped__description {
  width: calc(100% - 20px);
}

.text-cropped__btn {
  margin-left: 5px;
}

.text-cropped__80 {
  width: auto;
  max-width: 80%;
  display: inline-block;
  vertical-align: inherit;
  line-height: inherit;
}

.text-weight__normal {
  font-weight: normal;
}

.mrgn-auto-h {
  margin: 0 auto;
}

.mrgn-l {
  margin-left: 25px;
}

.mrgn-l-xs {
  margin-left: 5px;
}

.mrgn-l__62 {
  margin-left: 62px;
}

.mrgn-r {
  margin-right: 20px;
}

.mrgn-r--26 {
  margin-right: 26px;
}

.mrgn-b {
  margin-bottom: 20px;
}

.mrgn-b_sm {
  margin-bottom: 10px !important;
}

.mrgn-b_xs {
  margin-bottom: 5px;
}

.mrgn-t {
  margin-top: 20px;
}

.mrgn-l_12 {
  margin-left: 12px;
}

.mrgn-t_sm {
  margin-top: 10px;
}

.mrgn-t_xs {
  margin-top: 5px;
}

.border-t {
  border-top: @border;
}

.border-n {
  border: none;
}

.body-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  min-height: 100%;
}

.global-body {
  padding-bottom: 185px;
  overflow: hidden;
  min-width: calc(100% - 220px);
  width: 100%;
}

.position-relative {
  position: relative;
}

.fixed-width {
  flex-shrink: 0;
}

.fixed-width-400 {
  width: 400px;
}

.fixed-width-300 {
  width: 300px;
}

.fixed-width-150 {
  width: 150px;
}

.fixed-width-80 {
  min-width: 80px;
}

.fixed-width-40 {
  min-width: 40px;
}

.faded {
  opacity: 0.4;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.width-fit-content {
  width: fit-content;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: auto !important;
  overflow: hidden;
}

.visually-hidden__vertical {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  width: 0 !important;
  overflow: hidden;
}

.border-tl-tr {
  border-radius: 10px 10px 0 0;
}

.border-bl-br {
  border-radius: 0 0 10px 10px;
}

.border-bl {
  border-radius: 0 0 10px 0;
}

.pendo-notification-bubble-unread-count,
._pendo-home-view-bubble > div {
  line-height: 0;
}

.font_sec {
  font-size: @secondary-font-size;
}

.invisible {
  position: absolute;
  visibility: hidden;
}
