@import '_variables';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  height: 100%;
}
body {
  height: 100%;
  min-width: 970px;
  font-size: @primary-font-size;
  font-weight: 400;
  background: @magnolia;
  font-family: @main-font2;
  color: @blue-whale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: none;
}
small {
  font-size: smaller;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}

* {
  line-height: 1.4;
  box-sizing: border-box;
  outline: none;
}

:focus {
  outline: 0;
}

svg {
  display: inline-block;
  fill: currentColor;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
address {
  font-style: normal;
}

b {
  font-weight: 600;
}
