@import '_variables';

/* --Calculations*/
.budgeting .tip {
  color: #3276b1;
  margin: 6px 0;
}

.fhi {
  height: 70px;

  &:not(:last-child) {
    border-right: @border;
  }
}

.positive {
  color: @deep-aqua;
  outline: 1px solid #b5c2b5;
  background-color: #cddacd;
}

.negative {
  color: @milano-red;
  outline: 1px solid #c63838;
  background-color: #ffe5e5;
}
.btn-group.no-margin {
  margin-bottom: 0;
}

.btn-group.no-margin > label {
  margin-bottom: 0;
}

/* --Chart Controls */
.period-group {
  margin-bottom: 0;
}

.budgeting-loader {
  padding: 25px;
  text-align: center;
}

/*.budgeting-loader .fa-spin{*/
/*  margin-top: 10px;*/
/*}*/

/* --Overall Line Chart */
.chart {
  width: auto;
  height: 320px;
  margin-right: 10px;
  position: relative;
}

.chart canvas {
  width: 100% !important;
  height: 100% !important;
}

/* --Risk Bar Chart */
.chart.risk {
  width: auto !important;
}

.chart.risk canvas {
  margin-left: -1px;
}

/* --Building Bar Chart With Scrolling */
.chart-stretchable-holder {
  width: auto;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.chart-stretchable {
  height: 300px;
  width: 100%;
}

.chart-stretchable canvas {
  height: 300px;
  width: 100%;
  margin-left: -15px;
}

/* --Second Y-Axis Chart */
.chart-axis {
  height: 275px;
  width: 100%;
  position: absolute;
  left: 0;
  pointer-events: none;
}

.toolbar-panel__inner {
  margin: 20px;
}
.toolbar-panel__inner-bordered {
  margin: 10px;
  border: 1px solid #ccc;
}
/* Locations*/
.location__total {
  font-size: 18px;
  font-weight: 600;
}
.location__total-wrapper {
  width: 20vw;
  align-self: stretch;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ccc;
}

@media all and (min-width: 1024px) {
  .location__total-wrapper {
    width: 212px;
  }
}
.tooltip-placement {
  position: relative;
}
