@font-face {
  font-family: 'OpenSans';
  src:
    local('OpenSans'),
    url('./../../assets/fonts/open_sans/OpenSans-VariableFont_wdth,wght.ttf')
      format('truetype-variations');
  font-weight: 1 999;
}
@main-font: 'OpenSans', sans-serif;

@font-face {
  font-family: 'Roboto';
  src: url('./../../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@main-font2: 'Roboto', sans-serif;

@primary-font-size: 14px;
@secondary-font-size: 12px;
@action-elements-font-size: 13px;
@border-radius: 4px;
@border: 1px solid @grey-goose;

@light-grey-2: #f5f5f5;
@light-grey-9: #eceeef;
@azure: #0094ff;
@white: #ffffff;
@black: #000000;
@blue-chill: rgba(@deep-aqua, 0.8);
@blue-whale: #002743;
@dark-silver: #707070;
@bright-gray: #ededed;
@deep-aqua: #017d80;
@faded-green: #85ae6b;
@french-grey: rgba(@blue-whale, 0.3);
@green-white: #e5e9ec;
@grey-goose: #ccd4d9; // @blue-whale, 20%
@harp: rgba(@deep-aqua, 0.1);
@light-green-blue: #48fe9b;
@la-salle-green: #008134;
@macaroni: #e7b731;
@magnolia: #f5f6fa;
@manatee: #9ba3a7;
@midnight: #001b2e;
@milano-red: #b40909;
@nebula: #bfddde;
@pastel-red: #de5457;
@regent-grey: #8597a6; // @blue-whale, 40%
@sea-green: #208d65; // @light-green-blue, .5
@shadow-green: rgba(@deep-aqua, 0.5);
@silver-foil: #b1afaf;
@soft-peach: #eeeff3;
@tufts-blue: #4979d1;
@cooper: #d46b08;
