@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300italic,300,400italic,600,600italic,700,700italic,800,800italic);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);

/* IE11 HACKS */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  input:-ms-input-placeholder {
    color: darkgrey !important;
  }
}

select::-ms-expand {
  display: none;
}

/* CUSTOMIZATIONS */

.no-wrap {
  white-space: nowrap;
}

.text-dotted {
  text-overflow: ellipsis;
  overflow: hidden;
}

td.break-word {
  word-break: break-all;
  max-width: 200px;
}

td.break-by-word {
  word-break: break-word;
}

table.no-footer {
  margin-bottom: 0 !important;
}

hr.divider {
  margin-top: 0;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.asterisk {
  font-size: smaller;
}

.asterisk.red {
  color: #a8071a;
  font-size: 12px;
}

.loader-align {
  text-align: center;
}

tr > th.wide {
  width: 100px;
  max-width: 100px;
}

tr > th.short {
  width: 50px;
  max-width: 50px;
}

tr > td.widest {
  max-width: 300px;
}
tr > td.wider {
  max-width: 200px;
  width: 150px;
}

tr > td.wide {
  width: 100px;
  max-width: 100px;
}

/* SMART THEME CUSTOMIZATION */

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: default !important;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: default !important;
}

.registration.lockscreen {
  height: auto;
  top: 10%;
  margin-top: 0;
}
table.table .avatar-holder .name {
  vertical-align: middle;
  font-weight: bold;
}
th.id-column {
  width: 50px;
}
th.date-column {
  width: 100px;
}

.table.table-selectable > tbody > tr.selected > td,
.table.table-selectable > tbody > tr.selected > th {
  background-color: aliceblue !important;
}

.form-error {
  color: #ed1c24;
}

.smart-form .col {
  padding-left: 13px;
  padding-right: 13px;
}

/* --Breadcrumbs */
.breadcrumbs-bar {
  margin-bottom: 10px;
  justify-content: flex-end !important;
}
.btn.btn-link {
  font-size: 18px;
}

/* DIRECTIVE FOR SCALE AND ALIGN IMAGES */
.align-scaled-block {
  overflow: hidden;
}
.align-scaled-block.with-inline-avatar {
  display: inline-block;
  vertical-align: middle;
}
.align-scaled-block.with-avatar {
  border-radius: 50%;
}
.align-scaled-block.with-size-100 {
  width: 100px;
  height: 100px;
}
.align-scaled-block.with-size-30 {
  width: 30px;
  height: 30px;
}
.align-scaled-block .scaled-img {
  display: block;
}
.align-scaled-block.align-scaled-v .scaled-img {
  width: 100%;
}
.align-scaled-block.align-scaled-h .scaled-img {
  height: 100%;
}
td.short.shortest {
  width: auto;
  max-width: 120px;
}
td.short.input-wrapper {
  min-width: 190px;
}

td.short.udf,
th.short.udf {
  white-space: normal;
  width: auto;
  max-width: 190px;
}
.dont-break-words {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-dark-grey {
  color: #4c4e51;
}

.project-form-divider {
  border-top: 2px solid #e7eaec;
  width: calc(100% + 30px);
  margin-left: -20px;
  margin-right: -10px;
}
