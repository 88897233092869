@import "_variables";

.avatar-holder {
  display: flex;
  align-items: center;
}

.avatar-holder__image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 15px;
}

.image-holder__zoomed + .image-holder__image {
  max-width: 40px;
  max-height: 40px;
}

.edit-logo-holder {
  display: inline-block;
}

.edit-logo-holder__img {
  max-width: initial;
  width: 100%;
}

.edit-logo-holder_rounded {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  max-height: 100px;
}

.image-holder {
  width: 40px;
  height: 40px;
  max-height: 40px;
  text-align: center;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: @border-radius;
  }
  // fix for ie11 - set up thumbnail width and hight to avoid image overstretching
  & > img {
    max-width: 40px;
    max-height: 40px;
    margin: 0;
  }
}

.image-holder__image {
  border: 1px solid rgba(@black, 0.3);
}

.img-zoomed {
  width: 200px;
  padding: 5px;
  background-color: @white;
  border: @border;
  line-height: 0;
}

.image-holder__zoomed {
  width: 200px;
  position: fixed;
  padding: 5px;
  background-color: @white;
  border: @border;
  line-height: 0;
  z-index: 3;
  visibility: hidden;
}

.image-holder__zoomed_show {
  visibility: inherit;
}
