.snotify {
  width: 460px;
}

.snotifyToast {
  border-radius: 10px;
}

.snotifyToast__body {
  font-size: @primary-font-size;
}

.snotifyToast__title {
  font-size: 16px;
  margin-bottom: 10px;
}

.snotify-error,
.snotify-success,
.snotify-warning {
  .snotifyToast__inner {
    flex-flow: row-reverse;
    min-height: initial;
    padding: 10px 20px;
    align-items: center;
  }

  .snotifyToast__body {
    padding: 7px 0;
    font-weight: 500;
  }

  .snotify-icon--success {
    background: no-repeat center
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 10' enable-background='new 0 0 14 10'%3E%3Cpath fill='%23fff' d='M13.715.307a1.035 1.035 0 0 1-.016 1.443L5.867 9.564a1.479 1.479 0 0 1-2.097 0L.3 6.104A1.035 1.035 0 0 1 .286 4.66a.986.986 0 0 1 1.414-.016l3.12 3.112L12.3.291a.986.986 0 0 1 1.414.016Z'/%3E%3C/svg%3E");
  }

  .snotify-icon--warning,
  .snotify-icon--error {
    background: no-repeat center 40%
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14' enable-background='new 0 0 16 14'%3E%3Cpath fill='%23fff' d='M15.848 12.41 8.998.564A1.157 1.157 0 0 0 8 0a1.157 1.157 0 0 0-.998.564L.151 12.41c-.207.353-.201.707.019 1.06.1.163.237.292.41.387.178.095.368.143.57.143h13.7c.202 0 .389-.048.561-.143.179-.095.318-.224.42-.387.22-.353.225-.707.017-1.06ZM7 4h2v5H7V4Zm0 6h2v2H7v-2Z'/%3E%3C/svg%3E");
  }

  .snotify-icon {
    background-size: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    right: initial;
    top: initial;
    line-height: initial;
    transform: none;
    max-height: inherit;
    max-width: inherit;
    border-radius: 50%;
    background-color: black;
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.snotifyToast(@color) {
  background-color: lighten(@color, 60%);
  border: 1px solid lighten(@color, 10%);

  .snotifyToast__body {
    color: darken(@color, 10%);
  }

  .snotify-icon {
    background-color: @color;
  }
}

.snotify-error {
  .snotifyToast(@milano-red);
}

.snotify-success {
  .snotifyToast(@sea-green);
}

.snotify-warning {
  .snotifyToast(@macaroni);
}

.snotify-confirm {
  background: @blue-whale;
}

.snotifyToast__buttons {
  justify-content: end;
  padding: 10px 15px;

  button {
    width: initial;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 7px 14px;
    font-size: @secondary-font-size;
    border: 1px @white;
    border-style: solid !important;

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:last-child {
      color: @blue-whale;
      background-color: @light-green-blue;
      border-color: @light-green-blue;
    }
  }
}
