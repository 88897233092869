.snotify {
  width: 400px;
}

.snotifyToast__title {
  font-size: 22px;
}

.snotifyToast__buttons {
  button {
    font-size: 18px;
  }
}

.snotifyToast__body {
  width: 100%;
}
