@import '_variables';

.doughnut-list {
  border-bottom: @border;
  display: flex;
  padding: 20px;
}

.doughnut-list__item {
  flex: 1;
  padding: 10px 0px;
}

.doughnut-list__title {
  font-weight: 600;
  font-size: @secondary-font-size;
  text-align: center;
  margin-bottom: 5px;
}

.doughnut-list__subtitle {
  font-size: @secondary-font-size;
  text-align: center;
}

.doughnut-list__subtitle_italic {
  font-style: italic;
}

.doughnut-list__chart-holder {
  max-width: 160px;
  margin: 0 auto 15px;
  position: relative;
}

.doughnut-list__amount {
  display: block;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
}
