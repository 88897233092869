.dndPlaceholder {
  margin: 20px 0;
  min-height: 50px;
  border: 1px dashed green;
  background-color: rgba(0, 0, 0, 0.1);
  &.oneLineHeight {
    min-height: 42px;
    margin: 0;
  }
}
