// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$origin2-primary: mat.define-palette(mat.$indigo-palette);
$origin2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$origin2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $origin2-theme: mat.define-light-theme(
//   $origin2-primary,
//   $origin2-accent,
//   $origin2-warn
// );
$origin2-theme: mat.define-light-theme((
 color: (
   primary: $origin2-primary,
   accent: $origin2-accent,
   warn: $origin2-warn
 ),
//  typography: mat.define-typography-config(),
 density: -5,
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($origin2-theme);


:root {
  --mat-form-field-container-height: 24px;
  --mat-form-field-container-vertical-padding: 0px;
  --mat-form-field-filled-with-label-container-padding-top: 0px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mdc-protected-button-label-text-size: 13px;
  --mdc-protected-button-label-text-weight: 600;
  --mdc-protected-button-container-height: 32px;
  --mat-option-label-text-size: 13px;
}
