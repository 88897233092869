@import "../src/styles/base/_variables.less";
@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-alpine {
  --ag-grid-size: 5.2px;
}

a.disabled {
  pointer-events: none;
}

ngui-auto-complete {
  //z-index: 100 !important;

  .ngui-auto-complete {
    max-height: 50vh;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;

    > ul {
      border-bottom: 1px solid #ccc;

      li {
        border-bottom: none;
      }
    }
  }
}

.ag-cell-wrapper.ag-row-group {
  align-items: center;
  color: var(--Text-text-0, #002743);
}

[class*="ag-theme-"] {
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.ag-header {
  background-color: #f0f2f5;
  font-weight: 600;
}

.ag-row-odd {
  background-color: #f8f9fa;
}

.ag-ltr .ag-row-group-leaf-indent {
  margin-left: 0;
}

.ag-ltr .ag-row-group-indent-1 {
  padding-left: 0;
}

.ag-ltr .ag-row-group-indent-2 {
  padding-left: 0;
}

.ag-ltr .ag-row-group-indent-3 {
  padding-left: 0;
}

.ag-ltr .ag-row-group-indent-4 {
  padding-left: 0;
}

/* Remove the blue outline from the focused cell */
.ag-ltr .ag-cell-focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.ag-ltr
  .ag-row-dragging
  .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  user-select: none;
}

.ag-row-drag-disabled {
  cursor: not-allowed !important;
}

.ag-checkbox-checked-color {
  background-color: #017d80;
}

.ag-row.not-allowed-drop {
  cursor: not-allowed;
}

.ag-grid-text-highlight {
  background-color: #ffe3b5;
}

.ag-sort-indicator-icon.ag-sort-order {
  display: none !important;
}

.snotify-info {
  background-color: #e3f3ff;
  border-radius: 10px;
  border: 1px solid #0167b2;
  height: 59px;
}

.snotify-info > .snotifyToast__inner {
  display: flex;
  flex-flow: row;
  bottom: 11px;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
}

.info_message {
  position: relative;
  left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.info_icon {
  width: 35px;
  height: 35px;
}

.ag-theme-alpine,
.ag-theme-alpine-dark,
.ag-theme-alpine-auto-dark {
  --ag-alpine-active-color: #017d80;
  --ag-selected-row-background-color: none;
  --ag-row-hover-color: none;
  --ag-column-hover-color: none;
  --ag-input-focus-border-color: none;
}

.ag-checkbox-input-wrapper input,
.ag-checkbox-input-wrapper input {
  cursor: pointer;
}

.ag-checkbox-input-wrapper {
  &.ag-disabled {
    opacity: 0.3;
  }
}

.ag-ltr .ag-cell {
  border-right-width: 1px;
  display: flex;
  align-items: center;
}

.form-panel {
  background-color: white;
}

.cdk-overlay-pane {
  margin-top: 4px;
}

.ag-cell-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ag-header-cell {
  user-select: text;
}

.fhir-header {
  background-color: #f8f9fa !important;
  color: #4c4e51;
}

.f12 {
  font-size: 12px;
}

.break-spaces {
  white-space: break-spaces;
}
