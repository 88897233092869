@import '_variables';

.associative-list {
  padding: 15px 20px;
  border: @border;
}

.associative-list__title {
  float: left;
  color: @regent-grey;
  text-transform: uppercase;
  margin-right: 20px;

  &:not(:last-of-type) {
    padding-bottom: 15px;
  }
}

.associative-list__desc {
  overflow: hidden;
  &:not(:last-of-type) {
    padding-bottom: 15px;
  }
}
