@import '_variables';

.uar-cost {
  background-color: rgba(@azure, 0.1) !important;
}

.uar-life {
  background-color: rgba(@faded-green, 0.1) !important;
}

.uar-pm {
  background-color: rgba(@tufts-blue, 0.1) !important;
}

.uar-expenses {
  background-color: rgba(@macaroni, 0.1) !important;
}

.uar-risk:not(.disabled) {
  background-color: rgba(@pastel-red, 0.1) !important;
}

.edit-list__row {
  &.uar-cost:nth-child(even) {
    background-color: rgba(@azure, 0.2) !important;
  }

  &.uar-life:nth-child(even) {
    background-color: rgba(@faded-green, 0.2) !important;
  }

  &.uar-pm:nth-child(even) {
    background-color: rgba(@tufts-blue, 0.2) !important;
  }

  &.uar-expenses:nth-child(even) {
    background-color: rgba(@macaroni, 0.2) !important;
  }

  &.uar-risk:nth-child(even):not(.disabled) {
    background-color: rgba(@pastel-red, 0.2) !important;
  }
}
td.uar-pm,
td.uar-life {
  white-space: nowrap;
}
