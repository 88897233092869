@import "_variables";

button,
a {
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-family: @main-font2;
  color: inherit;
  font-size: @secondary-font-size;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.disabled-state {
  opacity: 0.3;
}

.btn {
  display: inline-block;
  color: @blue-whale;
  font-weight: 600;
  line-height: 18px;
  font-size: @action-elements-font-size;
  padding: 7px 14px;
}

.btn_iconed {
  display: inline-flex;
  flex-shrink: 0;
  align-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 0;

  &:hover:not(:disabled) {
    color: @deep-aqua;
    transition: 0.3s;
  }
}

.btn_iconed-small {
  padding: 0 !important;
}

.btn_iconed-blue {
  padding: 0;
  margin: 0;
  color: @deep-aqua;
  box-shadow: none;
  appearance: none;
}

.btn_iconed_warning {
  color: @macaroni;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: @macaroni;
  }
}

.btn_iconed_input {
  margin-top: 5px;
}

.btn_iconed_success {
  color: @deep-aqua;
}

.btn_iconed_white {
  color: @white;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: @white;
    opacity: 0.8;
  }
}

.btn_iconed_danger {
  color: @milano-red;
}

.btn_boxed {
  padding: 5px;
  border: @border;
  border-radius: @border-radius;
  font-size: 16px;
  line-height: 1;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: @deep-aqua;
  font-size: inherit;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: darken(@deep-aqua, 10);
    text-decoration: underline;
  }

  &.text-dotted {
    display: block;
  }
}

.link_simple {
  color: inherit;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: inherit;
  }
}

.buttons-block {
  padding: 15px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-block_to-end {
  justify-content: flex-end;
}

.buttons-block_to-center {
  justify-content: center;
}

.buttons-block_list {
  border: none;
  padding: 0;
  margin: 15px 0;
}

.buttons-block_list-pl {
  border: none;
  margin: 15px 0;
  padding: 0 15px;
}

.buttons-block_not-bordered {
  border: none;
}

.buttons-block_bordered {
  border: @border;
  border-top: 0;
}

.btn-collapse-icon {
  font-size: @primary-font-size;
  font-weight: 400;

  span {
    margin-left: 5px;
  }
}

/* specific buttons - small or in link-like design */

.btn-add-attribute {
  color: @light-green-blue;
  font-weight: 600;
  padding: 6px 0;
  font-size: 13px;
  display: inline-flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 5px;
  }
}

.btn-add-attribute_blue {
  color: @deep-aqua;
}

.btn-add-attribute_neutral {
  color: @grey-goose;
}

.btn-add-attribute_default {
  color: @black;
}

.btn-add-attribute_neutral {
  color: @grey-goose;
}

.notification-badge {
  position: absolute;
  top: -8px;
  font-size: 10px;
  background-color: @milano-red;
  border-radius: 50%;
  z-index: 3;
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: @white;

  &.badge-right {
    right: -8px;
  }

  &.badge-left {
    left: -8px;
  }
}

.tooltip-btn_underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}

.button(@font: @blue-whale, @bgcolor: @magnolia, @border: @bgcolor) {
  color: @font;
  background-color: @bgcolor;
  border: 1px solid @border;

  &:hover {
    background-color: darken(@bgcolor, 5%);
  }

  &.active {
    background-color: darken(@bgcolor, 30%);
  }
}

.action-btn {
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 7px 14px;
}

.action-btn_small {
  padding-top: 5px;
  padding-bottom: 5px;
}

.action-btn_xl {
  font-size: 16px;
  width: 245px;
  padding: 14px;
}

.action-btn_full {
  width: 100%;
  justify-content: center;
}

.action-btn__icon {
  margin-right: 8px;
  font-size: 0;
}

.action-btn_default {
  .button(@blue-whale, @white, @grey-goose);
}

.action-btn_success {
  .button(@white, @deep-aqua);
}

.action-btn_danger {
  .button(@white, @milano-red);
}

.action-btn_brightly {
  .button(@blue-whale, @light-green-blue);
}

.btn-group {
  align-items: center;
  display: inline-flex;
  flex-wrap: nowrap;
}

.btn-group__wrapper {
  overflow: hidden;
}

.btn-group__btn,
.btn-group__wrapper {
  border: 1px solid @blue-chill;

  &:not(:disabled):hover {
    background-color: lighten(@harp, 10%);
  }

  &.active {
    background-color: @harp;
  }

  &:not(:last-child) {
    border-right-color: transparent;
  }

  &:first-child {
    border-radius: 50px 0 0 50px;
  }

  &:last-child {
    border-radius: 0 50px 50px 0;
  }
}

.btn-group__input {
  display: none;

  &:checked + .btn-group__label {
    background-color: @harp;
  }
}

.btn-group__label {
  cursor: pointer;
  font-weight: 600;
  color: #002743;
  line-height: 18px;
  font-size: 13px;
  padding: 7px 14px;
  display: inline-block;
}

.ml-2 {
  margin-left: 6px;
}
