@import '_variables';

.progress {
  position: relative;
  overflow: hidden;
  height: 25px;
  background: #b7b7b7;
  box-shadow:
    0 1px 0 transparent,
    0 0 0 1px #e3e3e3 inset;
  border-radius: @border-radius;
}

.progress-bar {
  color: @white;
  background-image: url('../smartadmin/img/pattern/overlay-pattern.png');
}

.progress-bar-success {
  background-color: @deep-aqua !important;
}

.progress-striped .progress-bar {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}
.progress.active .progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-danger {
  background-color: #a90329 !important;
}
.progress-striped .progress-bar-danger {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-striped .progress-bar-success {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-warning {
  background-color: #c79121 !important;
}
.progress-striped .progress-bar-warning {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-info {
  background-color: #57889c !important;
}
.progress-striped .progress-bar-info {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
