@import "_variables";

::input-placeholder {
  color: @grey-goose;
  font-size: @secondary-font-size;
  font-weight: lighter;
  font-family: @main-font2;
}

::placeholder {
  color: @grey-goose;
  font-size: @secondary-font-size;
  font-weight: lighter;
  font-family: @main-font2;
}

:input-placeholder {
  color: @grey-goose;
  font-size: @secondary-font-size;
  font-weight: lighter;
  font-family: @main-font2;
}

:placeholder {
  color: @grey-goose;
  font-size: @secondary-font-size;
  font-weight: lighter;
  font-family: @main-font2;
}

/* fix for ie11 clear icon in input */
::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* input type="number" - hide spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

/* end */
.mixin-disabled {
  pointer-events: none;
  color: rgba(@black, 0.38);
  background-color: @green-white;
}

input,
select,
textarea {
  border: none;
  color: @blue-whale;
  font-family: @main-font2;
  width: 100%;
  line-height: 18px;

  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      font-size: inherit;
    }
  }

  &:disabled {
    .mixin-disabled;
  }
}

input,
textarea {
  font-size: @secondary-font-size;
  padding: 6px 12px;
  background-color: @white;
  border-radius: @border-radius;

  &:hover:not(:disabled, :read-only) {
    background-color: @magnolia;
  }

  &:focus {
    background-color: @white;
    border: 1px solid @deep-aqua;
    box-shadow: 0 0 4px rgba(51, 122, 183, 0.3);
  }
}

textarea {
  resize: vertical;
}

select {
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:hover:not(:disabled) {
    background-color: @magnolia;
  }

  &:focus {
    background-color: @white;
    border: 1px solid @deep-aqua;
    box-shadow: 0 0 4px rgba(51, 122, 183, 0.3);
  }
}

.select {
  font-size: @secondary-font-size;
  padding: 6px 22px 6px 12px;
  text-overflow: ellipsis;
  @color: escape("@{blue-whale}");
  // should be convert to url-escaped ascii
  background: @white no-repeat center
    url(~"data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20386.257%20386.257%22%20enable-background=%22new%200%200%20386.257%20386.257%22%20width=%2210%22%20height=%228%22%3e%3cstyle%20type=%22text/css%22%3e.arrow%7Bfill%3A@{color}%3B%7D%3C%2Fstyle%3e%3cpath%20class=%22arrow%22%20d=%22M0%2096.879l193.129%20192.5%20193.128-192.5z%22/%3e%3c/svg%3e");
  // fix for ie11 - if we will combine background-position with background, background disappears
  background-position: calc(100% - 5px) center;
  background-size: 12px;
  cursor: pointer;
}

.select_xs {
  width: 70px;
}

.select_narrow {
  width: 100px;
}

.form-block {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: block;
}

.form-block_label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.form-block_readonly {
  padding: 6px 0;
  font-size: @secondary-font-size;
}

.form-block_icon,
.form-block_icon_before {
  position: relative;

  &::after {
    content: "$";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
}

.form-block_sm {
  width: 197.5px;
}

.form-block_md {
  width: 230px;
}

.form-block_xxs {
  width: 60px;
}

.form-block_xs {
  width: 160px;
}

.form-block_xxs {
  width: 110px;
}

.form-block_lg {
  max-width: initial;
}

.form-block_full-width {
  max-width: initial;
}

.form-block__tooltip {
  color: @grey-goose;
  padding: 5px 0;
}

.form-block__element {
  border: @border;
  border-radius: 4px;
}

.form-block__element_with-batch {
  border-radius: 4px 0 0 4px;
}

.form-block__element_md {
  padding: 18px;
  font-size: @primary-font-size;
}

.form-block__element_icon {
  padding-left: 25px;
  text-align: right;
}

.form-block__element_not-input {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  background-color: transparent;
  cursor: auto;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}

.form-block__label {
  display: block;
  font-size: @secondary-font-size;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-block__input-label {
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: @soft-peach;
  font-size: @secondary-font-size;
  font-weight: 600;
  border-left: @border;
  overflow: visible;
  width: 50%;
}

.form-block__input-small {
  width: 50%;
}

.form-block__btn {
  flex-shrink: 0;
  align-self: center;
}

.form-block__element_xsmall {
  width: 50px;
}

.form-block__element_small {
  width: 100px;
}

.form-block__element_middle {
  width: 197.5px;
}

.form-block__element_wide {
  width: 100%;
  max-width: 400px;
}

.form-block__wrapper {
  flex-wrap: wrap;
}

.form-block__error_wide {
  width: 100%;
}

.form-block_error,
.form-block_error .multiselect {
  background-color: @light-grey-2 !important;
  border-color: @milano-red !important;
}

.form-block_warn {
  &,
  .multiselect,
  .form-block__element {
    border-color: sandybrown !important;
  }
}

.error-block {
  padding: 5px 10px;
}

.error {
  color: @milano-red;
  font-size: @secondary-font-size;
  font-weight: 600;
  word-break: break-word;

  &:not(.error_inline) {
    margin-top: 5px;
  }
}

.error_inherit {
  font-size: inherit;
}

.tooltip-placeholder {
  cursor: pointer;
}

.tooltip-btn {
  color: @deep-aqua;
}

.tooltip-btn_error {
  color: @milano-red;
  z-index: 1;
}

.btn.tooltip-btn_error {
  position: absolute;
  right: 6px;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  line-height: 1;

  &.daterangepicker-tooltip-btn_error {
    right: 31px;
  }
}

.select ~ .tooltip-btn_error {
  right: 23px;
}

.btn.tooltip-btn_static {
  position: relative;
  transform: none;
  right: 0;
}

.tooltip-btn_disable {
  color: @grey-goose;
}

.error_warn {
  color: sandybrown;
}

.caption {
  padding: 6px 10px 0 0;
}

.small-height__textarea {
  height: 46px;
  min-height: 46px;
  overflow-y: auto;
  background-color: transparent;
  border: @border;
}

.form-block__multiselect {
  display: inline-block;
}

.success-msg {
  color: @deep-aqua;
}
// specially done to show mutiselect (currency) as disabled
.override-disabled {
  .multiselect {
    padding-left: 0 !important;
  }

  button.multiselect__btn {
    text-indent: 10px;
  }

  button.multiselect__btn:disabled {
    .mixin-disabled;

    opacity: 1;
  }
}
