.category-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: -5px;
  margin-right: -5px;
  justify-content: flex-end;
}

.category-tags__tag {
  padding: 3px 7px;
  border-radius: @border-radius;
  font-size: 10px;
  margin: 0 5px 5px 0;
  font-weight: 600;
}

/* facility attributes colorpicker*/
.color-picker.open {
  border: @border;
  border-radius: @border-radius;
}

.color-picker.open .preset-area {
  padding: 0 18px 20px;
}

.color-picker .preset-area .colorpicker-presets .preset-color {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 2px 2px 4px;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}

.color-picker .preset-area .colorpicker-presets .preset-color:before {
  content: attr(data-before);
  font-size: 10px;
  font-weight: 600;
  color: inherit;
  line-height: 20px;
  position: absolute;
  vertical-align: middle;
  left: 0;
  top: 0;
  width: 20px;
  text-align: center;
}

.color-picker.open .hue-alpha.box,
.color-picker.open .preset-area hr {
  display: none !important;
}
.color-picker.open .preset-area .preset-label {
  font-size: 14px;
  color: #444;
  padding: 20px 0;
  float: left;
}

.color-picker.open .button-area {
  text-align: center;
}
//
//.color-picker .button-area button.btn-default {
//  font-size: 13px;
//  font-family: 'Open Sans', sans-serif;
//  font-weight: 600;
//  color: #444;
//  padding: 0 6px 0 0;
//  display: flex;
//  line-height: 32px;
//  margin: 0 auto;
//}

.color-picker .button-area button.btn-close {
  font-size: 18px;
  font-weight: 400;
  color: #444;
  padding: 0;
  width: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  position: absolute;
  top: 17px;
  right: 15px;
}

.color-picker .button-area button.btn-close:hover {
  opacity: 0.8;
}
//
//.color-picker .button-area button.btn-default:before {
//  background: rgba(0, 0, 0, 0.15);
//  width: 36px;
//  content: '';
//  background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6696 1.73214C13.8363 1.89881 13.9196 2.10119 13.9196 2.33928C13.9196 2.57738 13.8363 2.77976 13.6696 2.94643L7.20536 9.41071L5.99107 10.625C5.82441 10.7917 5.62202 10.875 5.38393 10.875C5.14583 10.875 4.94345 10.7917 4.77679 10.625L3.5625 9.41071L0.330357 6.17857C0.163691 6.0119 0.0803572 5.80952 0.0803572 5.57143C0.0803572 5.33333 0.163691 5.13095 0.330357 4.96429L1.54464 3.75C1.71131 3.58333 1.91369 3.5 2.15179 3.5C2.38988 3.5 2.59226 3.58333 2.75893 3.75L5.38393 6.38393L11.2411 0.517857C11.4077 0.35119 11.6101 0.267857 11.8482 0.267857C12.0863 0.267857 12.2887 0.35119 12.4554 0.517857L13.6696 1.73214Z' fill='%23444444'/%3E%3C/svg%3E%0A");
//  background-size: 12px;
//  background-position: center;
//  background-repeat: no-repeat;
//  padding: 0;
//  margin: 0 6px 0 0 ;
//  height: 32px;
//}

.color-picker .button-area button.btn.btn-cancel {
  border: none;
  box-shadow: none;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 20px;
  line-height: 1;
}
