/* ASSET LIST*/
.asset-custom-cost {
  color: blue !important;
}

/* ---- Gallery primary button*/
.gallery-primary-image {
  position: fixed !important;
  top: 30px;
  left: 60px;
  right: 60px;
  height: 50px;
  z-index: 10001;
  background-color: transparent !important;
}

.gallery-primary-image-button {
  display: block;
  margin: auto;
  height: 40px;
  padding: 0 20px;
  border: none;
  border-radius: 15px;
  font-weight: 600;
  background-color: white;
  transition: background-color 0.25s ease;
}

.gallery-primary-image-button:hover {
  background-color: #d4d4d4;
}

.asset-list-buttons {
  margin-bottom: 30px;
}

.asset-list-pic {
  position: relative;
}

.asset-list-pic a {
  color: black;
}

.asset-list-img-popover img {
  width: 100%;
  border: 1px solid rgb(138, 138, 138);
}

.asset-list-img-holder {
  width: 200px;
  border: 5px solid white;
}

.asset-list-img-popover {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 101px);
  width: 202px;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* ASSET DETAILS */
.form-warning {
  font-size: 12px;
  color: sandybrown;
  font-weight: 700;
}

.scrollable .scrollable-bar {
  border-color: transparent;
  border-top-width: 0;
  border-bottom-width: 0;
  background: #b0b0b0;
  border-radius: 4px;
  box-shadow: none !important;
}

.import-error {
  word-break: break-word;
}

.incomplete-uuid {
  width: 250px;
}

.arbitrary-mark {
  color: #ffb800;
}
