@import '_variables';

td,
th {
  vertical-align: middle;
  padding: 12px;
  font-size: @secondary-font-size;
  text-align: left;
  font-weight: normal;
}

.table {
  tfoot {
    font-size: @primary-font-size;
  }

  & > tbody > tr {
    background-color: @white;
  }
}

.table_narrow {
  td,
  th {
    padding: 5px 12px;
  }
}

.table-striped {
  & > tbody > tr {
    &:nth-child(even) {
      background-color: @soft-peach;

      &:hover,
      &:focus {
        background-color: @light-grey-9;
      }
    }
  }
}

.table_fixed {
  table-layout: fixed;
  min-width: 1100px;
}

.table_list > tbody > tr {
  border-bottom: @border;

  &:nth-child(even) {
    background-color: @white;
  }

  &:hover,
  &:focus {
    background-color: @light-grey-2;
  }
}

.table_big-font {
  th,
  td {
    font-size: @primary-font-size;
  }
}

.table__footer-custom {
  border-top: @border;
  border-bottom: @border;

  td {
    background-color: @white !important;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: @primary-font-size;
  }
}

.table__cell_nested {
  padding: 0;
}

.table__wide-col {
  width: 350px;
  max-width: 350px;
  @media all and (max-width: 1100px) {
    width: 25%;
  }
}

.table__small-col {
  width: 90px;
  max-width: 15vw;
}

.table__middle-col {
  width: 240px;
  max-width: 40vw;
  //min-width: 240px;
}

.table__widest-col {
  width: 400px;
  max-width: 400px;
  @media all and (max-width: 1100px) {
    width: 30%;
  }
}

.table__wide_40vw_col {
  width: 40vw;
  max-width: 40vw;
}

.table_pinned {
  tbody tr {
    &:first-of-type {
      border-top: 1px solid transparent;
    }
  }
}

.table__col-number {
  width: 45px;
}

.table-scroll {
  width: 100%;
  overflow-x: auto;
}

/* styles for logs tablexs with hidden rows*/

.table_striped > tbody > tr {
  &:nth-child(4n-3),
  &:nth-child(4n) {
    background-color: @white;
  }

  &:nth-child(4n-2),
  &:nth-child(4n-1) {
    background-color: @soft-peach;
  }
}

.table__cropped-col {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table_bordered {
  border: @border;

  .table__header {
    border-top: none;
  }
}

.table__header {
  border-top: @border;
  border-bottom: @border;
  text-align: left;

  tr:not(:first-child) {
    border-top: @border;
  }

  th {
    background-color: @green-white;
    white-space: nowrap;
    font-weight: 600;

    &:not(:last-child) {
      border-right: @border;
    }
  }

  .table__pinned-col {
    box-shadow: 1px 0 0 0 @grey-goose;
  }
}

.table__header_transparent {
  & > tr {
    background-color: transparent;
  }

  & > th {
    white-space: nowrap;

    &:not(:last-child) {
      border: none;
    }
  }
}

.sorting-column-mixin(@var) {
  @color: escape('@{var}');
  background: no-repeat
    url(~'data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20491.996%20491.996%22%20enable-background=%22new%200%200%20491.996%20491.996%22%20width=%2210%22%20height=%228%22%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A@{color}%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d%3D%22M484.132%20124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208%200-13.964%202.792-19.036%207.86l-183.84%20183.848L62.056%20108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968%202.788-19.036%207.856l-16.12%2016.128c-10.496%2010.488-10.496%2027.572%200%2038.06l219.136%20219.924c5.064%205.064%2011.812%208.632%2019.084%208.632h.084c7.212%200%2013.96-3.572%2019.024-8.632l218.932-219.328c5.072-5.064%207.856-12.016%207.864-19.224%200-7.212-2.792-14.068-7.864-19.128z%22%2F%3E%3C%2Fsvg%3E');
}

.table__sorting-column {
  cursor: pointer;
  position: relative;
  padding-right: 25px;

  &:after,
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: 5px;
    .sorting-column-mixin(@grey-goose);
  }

  &:after {
    top: 50%;
  }

  &:before {
    bottom: 50%;
    transform: rotate(180deg);
  }
}

.table__sorting-column_desc:after {
  .sorting-column-mixin(@black);
}

.table__sorting-column_asc:before {
  .sorting-column-mixin(@black);
}

// wrapping words
th.table__wrapped-col {
  white-space: normal;
}

body:not(.ie11) {
  .table__pinned-col {
    position: sticky;
    z-index: 1;
    left: 0;
    background-color: inherit;
  }

  th.table__pinned-col {
    border: none;
    box-shadow: inset -1px 0 0 0 @grey-goose;
    background-color: @green-white;
  }

  //for tables without first checkbox field

  .table__pinned-col {
    min-width: 120px;
    max-width: 120px;
  }

  .table__pinned-col_wide {
    min-width: 200px;
    max-width: 200px;
  }

  .table__pinned-col_extra-wide {
    min-width: 363px;
    max-width: 363px;
    left: 0;
  }

  .table__pinned-col_sm {
    min-width: 110px;
    max-width: 110px;
  }

  //for tables with first checkbox field
  .table__pinned-col_opt {
    min-width: 44px;
    max-width: 44px;
  }

  .table__pinned-col {
    &.table__pinned-col-2 {
      left: 44px;
    }

    &.table__pinned-col-3 {
      left: 244px;
    }

    &.table__pinned-col-4 {
      left: 364px;
    }

    &.table__pinned-col-5 {
      left: 484px;
    }

    &.table__pinned-col-6 {
      left: 604px;
    }

    &.table__pinned-col-7 {
      left: 724px;
    }
  }

  .table__pinned-col_sm {
    &.table__pinned-col-4 {
      left: 364px;
    }

    &.table__pinned-col-5 {
      left: 484px;
    }

    &.table__pinned-col-6 {
      left: 594px;
    }

    &.table__pinned-col-7 {
      left: 704px;
    }

    &.table__pinned-col-8 {
      left: 844px;
    }

    &.table__pinned-col-9 {
      left: 954px;
    }
  }
}

.table__pinned-col_last {
  box-shadow: inset -1px 0 0 0 @grey-goose;

  &:after {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    height: 100%;
    width: 10px;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.table__row-title {
  font-weight: 600;
  color: @deep-aqua;
}

.column_mustard {
  background-color: rgba(@macaroni, 0.1);
}

.column_blue {
  background-color: rgba(@deep-aqua, 0.1);
}

.column_green {
  background-color: rgba(@light-green-blue, 0.1);
}

.record-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.record-actions__status {
  display: inline-block;
  margin-right: 10px;
  color: inherit;
}

//tfoot {
//  font-weight: bold;
//  text-transform: uppercase;
//}
.table-location--buildings {
  th:first-child,
  td:first-child {
    padding-left: 20px;
  }

  td:last-child {
    padding-right: 30px;
  }
}

tr.disabled__text {
  & > td {
    color: @grey-goose;
  }
}

.table__col-actions {
  width: 80px;
  max-width: 80px;
}
