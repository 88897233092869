@import '_variables';

/* CROP TOOL */

.ng2-imgcrop {
  input {
    display: none;
  }
}

.crop-tool {
  .crop-canvas {
    margin-bottom: 10px;
    display: block;
    &:first-child {
    }
  }

  .crop-buttons {
    margin: 10px 20px 20px;

    button:first-child {
      margin-right: 5px;
    }
  }

  .crop-browse {
    margin-top: 10px;
  }

  &.inline {
    display: inline-block;

    .crop-browse {
      margin-right: 3px;
      display: inline-block;
    }
  }

  .file-input-wrapper input {
    display: none !important;
  }
}

.crop-canvas canvas {
  height: auto;
  width: auto;
  max-width: 100%;
  display: block;
  margin: auto;
}

.cropper__label {
  width: auto;
  max-width: 200px;
  font-size: @primary-font-size;
}

.cropper__label-grey {
  font-size: @secondary-font-size;
  color: #bfbfbf;
}
