@import '_variables';

.image-gallery {
  display: flex;
  &.image-gallery_full {
    height: 100%;
  }
}

.image-gallery__drop {
  border: 1px dashed @grey-goose;
  color: @deep-aqua;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  flex-shrink: 0;
  padding: 20px;

  &.disabled {
    user-select: none;
    color: @grey-goose;
    cursor: not-allowed;
  }

  &:not(.image-gallery__drop_full) {
    width: 200px;
    height: 200px;
  }
}

.image-gallery__drop_full {
  width: 100%;
  height: 100%;
  outline-offset: -20px;
}

.image-gallery__drop_active {
  border: 1px solid @deep-aqua;
}

.image-gallery__list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.image-gallery__item {
  position: relative;
  border-radius: @border-radius;
  flex-shrink: 0;
  line-height: 0;

  &:not(:last-child) {
    margin-right: 5px;
  }
}
.image_placeholder-wrapper {
  display: flex;
}
.image-gallery__placeholder {
  height: 200px;
  width: 200px;
  min-width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 5px;
  }
}

.image-gallery__img {
  width: 200px;
  border: @border;
  cursor: pointer;
}

.image-gallery__btn-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
}

.image-gallery__btn {
  line-height: 1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  &:hover,
  &:focus {
    background-color: rgba(@black, 0.5);
    color: @white;
  }
}

.image-gallery__btn-clickable {
  &:hover,
  &:focus {
    background-color: rgba(@deep-aqua, 0.5);
    color: @white;
  }
}

.image-gallery__btn-icon {
  width: 26px;
  height: 26px;
  padding: 0;
}

.image-gallery__btn_active {
  background-color: @deep-aqua;
  color: @white;
}

.counter-holder {
  background: rgba(@black, 0.3);
  border-radius: 14px;
  color: @white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  width: calc(1.4em + 10px);
}

.counter-holder_bottom {
  top: inherit;
  bottom: 20px;
  transform: translate(-50%, 0);
}

.gallery-button-holder {
  background: @french-grey;
  border-radius: 50%;
  color: @white;
  margin: 20px auto;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
