@import '_variables';

.custom-toggle {
  display: inline-block;
}

.custom-toggle__check {
  display: block;
  height: 15px;
  width: 30px;
  cursor: pointer;
  border: @border;
  border-radius: 40px;
  background-color: @dark-silver;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &:before,
  &:after {
    display: inline-block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &:after {
    content: '';
    right: 1px;
  }
  &:before {
    content: '';
    left: 1px;
    opacity: 0;
  }
}

.custom-toggle__check_sm {
  height: 13px;
  width: 25px;
}

.custom-toggle__check_lg {
  height: 18px;
  width: 36px;
}

.custom-toggle__toggler {
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  transition: left 0.3s ease-in-out;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
  background-color: @white;
}

.custom-toggle__toggler_sm {
  left: 2px;
}

.custom-toggle__toggler_lg {
  left: 4px;
  width: 10px;
  height: 10px;
}

.custom-toggle__check_lock {
  &:after {
    @color: escape(@white);
    background: no-repeat center
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='@{color}' viewBox='0 0 20 20'%3E%3Cpath d='M15.236.5a4.526 4.526 0 0 0-4.524 4.524v1.81H2.57c-.995 0-1.81.805-1.81 1.809v9.047c0 1.005.815 1.81 1.81 1.81h10.857c1.005 0 1.81-.805 1.81-1.81V8.643a1.81 1.81 0 0 0-1.81-1.81h-.904v-1.81a2.71 2.71 0 0 1 2.714-2.713 2.71 2.71 0 0 1 2.714 2.714v1.81h1.81v-1.81A4.525 4.525 0 0 0 15.236.5ZM7.998 11.357c.995 0 1.81.805 1.81 1.81 0 1.004-.806 1.81-1.81 1.81a1.809 1.809 0 1 1 0-3.62Z'/%3E%3C/svg%3E");
    background-size: 10px;
  }

  &:before {
    @color: escape(@white);
    background: no-repeat center
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='@{color}' viewBox='0 0 16 20'%3E%3Cpath d='M8 14.976a1.81 1.81 0 1 0 0-3.62 1.81 1.81 0 0 0 0 3.62Zm5.428-8.143a1.81 1.81 0 0 1 1.81 1.81v9.047a1.81 1.81 0 0 1-1.81 1.81H2.571a1.81 1.81 0 0 1-1.81-1.81V8.643c0-1.004.815-1.81 1.81-1.81h.905v-1.81a4.524 4.524 0 0 1 9.048 0v1.81h.904ZM8 2.31a2.714 2.714 0 0 0-2.714 2.714v1.81h5.428v-1.81A2.714 2.714 0 0 0 8 2.31Z'/%3E%3C/svg%3E");
    background-size: 8px;
  }
}

.toggle-lock__svg {
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-toggle__check_labeled {
  margin-right: 15px;
}

.custom-toggle__input {
  display: none;

  &:checked + .custom-toggle__check {
    background-color: @deep-aqua;

    &:after {
      opacity: 0;
    }

    &:before {
      opacity: 1;
    }

    .custom-toggle__toggler {
      left: 17px;
    }
  }

  &:disabled + .custom-toggle__check {
    cursor: default;
    opacity: 0.6;
  }
}

.custom-toggle__input_sm {
  &:checked + .custom-toggle__check_sm {
    .custom-toggle__toggler_sm {
      left: 13px;
    }
  }
}

.custom-toggle__input_lg {
  &:checked + .custom-toggle__check_lg {
    .custom-toggle__toggler_lg {
      left: 20px;
    }
  }
}
