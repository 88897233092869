@import '_variables';

.custom-radio {
  color: @blue-whale;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: default;
    color: @grey-goose;
  }

  //&:hover, &:focus {
  //  .custom-radio__input:checked + .custom-radio__check {
  //    background-color: @white;
  //
  //    &:after {
  //      background-color: @deep-aqua;
  //    }
  //  }
  //
  //  .custom-radio__check {
  //    &:after {
  //      background-color: @white;
  //    }
  //  }
  //
  //  .custom-radio__check_error {
  //
  //    &:after {
  //      background-color: @red;
  //    }
  //  }
  //}
}

.custom-radio_lg {
  font-size: @primary-font-size;
}

.custom-radio__check {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: @border;
  border-radius: 50%;
  background-color: @white;
  transition: all 0.4s;
  flex-shrink: 0;
  position: relative;
  vertical-align: bottom;
  &:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.custom-radio__check_labeled {
  margin-right: 15px;
}

.custom-radio__input {
  display: none;

  &:checked + .custom-radio__check {
    background-color: @white;
    &:after {
      background-color: @deep-aqua;
    }
  }
  &:checked + .custom-radio__check_error {
    &:after {
      background-color: @milano-red;
    }
  }

  &:disabled + .custom-radio__check {
    cursor: default;
    opacity: 0.6;
  }
}
