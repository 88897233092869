/* MAIN */

/* Fix for IE11 (first div with ui-view wraps all content) */
body.ie11 > div {
  display: block;
}
/* end fix for search field */

#content {
  padding-left: 26px;
  padding-right: 26px;
}
.break-word {
  word-break: break-all;
}

/* LOGGING */
/*TODO  REMOVE AFTER MIGRATION TO COLLAPSED LOGS  */
.log-details {
  word-break: break-all;
  padding-top: 10px;
}

.log-details pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;

  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.log-string {
  color: green;
}

.log-number {
  color: darkorange;
}

.log-boolean {
  color: blue;
}

.log-null {
  color: red;
}

.log-key {
  color: darkblue;
}
/*TODO END OF REMOVING*/
.ngx-json-viewer .segment-type-number .segment-value {
  color: darkorange !important;
}
.ngx-json-viewer .segment-type-string .segment-value {
  color: green !important;
}
.ngx-json-viewer .segment-key {
  color: darkblue !important;
}
.ngx-json-viewer .segment-type-boolean .segment-value {
  color: blue !important;
}
.ngx-json-viewer .segment-type-null .segment-value {
  color: red !important;
  background-color: transparent !important;
}
.ngx-json-viewer .segment-type-array .segment-value,
.ngx-json-viewer .segment-type-object .segment-value {
  width: 100%;
  text-overflow: ellipsis;
  max-width: calc(100vw - 550px);
  overflow: hidden;
  display: inline;
  vertical-align: bottom;
}
@media all and (max-width: 768px) {
  .ngx-json-viewer .segment-type-array .segment-value,
  .ngx-json-viewer .segment-type-object .segment-value {
    max-width: calc(100vw - 300px);
    min-width: 300px;
  }
}
.ngx-json-viewer .segment .segment-main .toggler:after {
  content: '+' !important;
  border: 1px solid #8597a6 !important;
  height: 12px;
  width: 12px;
  /*line-height: 9px;*/
  text-align: center;
  border-radius: 4px;
  display: block;
  font-size: inherit;
  line-height: inherit;
  vertical-align: middle;
}

.ngx-json-viewer .segment .segment-main .toggler {
  margin-top: 0 !important;
  margin-left: -20px !important;
  font-size: 1.2em;
  line-height: 1.5em;
  vertical-align: middle;
}

.ngx-json-viewer .segment {
  padding: 3px 3px 3px 20px !important;
  margin: 0 !important;
}

.ngx-json-viewer .segment .segment-main.expanded .toggler:after {
  content: '—' !important;
  transform: none !important;
  font-size: inherit;
}
.ngx-json-viewer .segment-type-array > .segment-main > .segment-value,
.ngx-json-viewer .segment-type-object > .segment-main > .segment-value {
  display: inline-block;
}
@media all and (max-width: 768px) {
  .ngx-json-viewer .segment-type-object .segment-value {
    max-width: calc(100vw - 300px);
  }
}

/*END OF NEW LOGGING*/

/* --Avatar */
.avatar {
  border-radius: 100px;
  width: 100px;
  height: 100px;
}

/* ORGANIZATIONS */

/* --Creation */
.create-org-text {
  font-size: medium;
  text-align: center;
  margin: 20px 0 20px 0;
}

.create-org-failure {
  color: red !important;
}

.create-org-progress {
  color: grey !important;
}

.create-org-holder {
  padding-bottom: 20px;
  text-align: center;
}

/* --Details */

.country-picker {
  opacity: 0.5;
}

.country-picker i {
  display: none;
}

.header .org-form {
  padding: 8px 0 0 8px;
  float: left;
  max-width: 300px;
}

.header .org-form .select {
  width: 100%;
}

.status-mark {
  font-size: 16px;
  color: #017d80;
}

.field-hint {
  font-size: 13px;
  font-style: italic;
  font-weight: normal;
  color: #aaa;
  display: block;
  margin-top: 4px;
}

.form-error {
  font-size: 12px;
}

/* USERS LIST */

tr.inactive > td:not(.full-opacity) {
  opacity: 0.5;
}

/* end of styles for log tables with hidden rows */
/* MODALS */
.modal-open-fix {
  width: 100%;
  position: fixed;
  overflow-y: scroll;
}

.modal-open-fix.content-scrollable {
  position: static;
}

/* todo styles for ngx-bootstrap modal. Should be removed after getting rid of old bootstrap styles (vendors folder) */
.modal.fade.show {
  opacity: 1;
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}
/* todo end */

.modal-controls {
  padding-bottom: 13px;
}
.avatar-edit {
  position: relative;
}
.avatar-edit_padding {
  padding: 20px;
}

.avatar-edit__container {
  display: flex;
  align-items: center;
  align-self: center;
}

.avatar-edit .align-scaled-block {
  margin-bottom: 1em;
}

.avatar-edit img.scaled-img {
  width: 100%;
  height: 100%;
}

.signature-reset {
  margin-right: 15px !important;
}

.signature-canvas {
  display: inline-block;
}

.signature-no {
  font-size: 14px;
  font-weight: 100;
}

.signature-error {
  color: red;
  margin: 0;
}

.avatar-error {
  font-weight: 100;
  font-size: 14px;
  color: red;
  margin-bottom: 1em;
  display: block;
}

.avatar-error.organization {
  margin-bottom: 0;
  margin-top: 5px;
}
.lockscreen .logo img {
  width: 100px;
}
.auto-scrollable {
  height: auto;
  max-height: 340px;
  min-height: 4.3em;
  overflow-y: auto;
}
.auto-scrollable p {
  overflow: hidden;
  text-overflow: ellipsis;
}
.auto-scrollable {
  height: auto;
  max-height: 340px;
  overflow-y: auto;
}
