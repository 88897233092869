@import '_variables';

.searching-form {
  display: inline-flex;
  border: @border;
  border-radius: @border-radius;
  background-color: @white;
}

.searching-form__btn {
  padding: 4px 10px;
  transition: 0.3s all;
  line-height: 1em;
  font-size: @primary-font-size;

  &:hover,
  &:focus {
    color: @deep-aqua;
  }
}
