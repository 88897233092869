@import '_variables';

.panel-collapsible {
  border: @border;
  border-radius: 10px;
  overflow: hidden;
}
// to be done replace with content header
.panel-collapsible__header {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.panel-collapsible__header_padd-0 {
  padding: 0;
}
.panel-collapsible__header-dark {
  background-color: @blue-whale;
  color: @white;
}
.panel-collapsible__footer {
  border-top: @border;
}
.collapsible-title_el {
  display: flex;
  flex-flow: row nowrap;
  align-self: normal;
  transition: opacity 0.2s ease-in-out;
  padding: 0 10px;
}

.el_inner-wrapper {
  padding: 10px;
  align-self: center;
}
.el_inner-wrapper_column {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.right-el {
  margin-left: auto;
  justify-content: flex-end;
}
.title_el--details {
  display: block;
  font-size: @secondary-font-size;
}

.toggle__wrapper:not(.navigation__item_open) .animated-height {
  clip: rect(0 0 0 0);
  width: 100%;
  overflow: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}
.animated-height {
  transition:
    height 0.3s ease-out,
    padding 0.3s ease-out;
  transform-origin: top;
  overflow: hidden;
}
.text-items--list-wrapper {
  font-size: @secondary-font-size;
  margin: 10px;
  padding: 5px 10px;
  width: calc(20% - 20px);

  @media all and (max-width: 1200px) {
    width: calc(33% - 20px);
  }
  @media all and (max-width: 900px) {
    width: calc(100% - 20px);
  }
}
.text-items--list + .text-items--list {
  margin-top: 20px;
}
.text-items--header {
  margin: 10px 0;
}

.text-items--item {
  margin: 0;
  padding: 3px 0;
}

.bullet-items--item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:before {
    content: '●';
    margin: 0 8px 0 1.2em;
  }
}
.block_bullet-wrapper {
  display: flex;
}

.no-items {
  color: @blue-whale;
  font-size: @secondary-font-size;
  font-style: italic;
}

.secondary-font__title {
  font-size: @secondary-font-size;
  font-weight: 600;
}
