@import '_variables';

.custom-radiobutton__input {
  display: none;

  &:checked + .custom-radiobutton {
    background-color: @deep-aqua;
    color: @white;
  }
}
//
//.custom-radiobutton {
//  padding: 12px 0;
//  text-align: center;
//  display: block;
//  color: @deep-aqua;
//  border: 1px solid @deep-aqua;
//  transition: .4s all;
//  font-size: 20px;
//  cursor: pointer;
//
//  &:hover, &:focus {
//    background-color: @deep-aqua;
//    color: @white;
//  }
//}

.checkbox-check-mixin(@var) {
  @color: escape('@{var}');
  background: no-repeat
    url(~'data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20365.696%20365.696%22%20enable-background=%22new%200%200%20365.696%20365.696%22%20%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A@{color}%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d=%22M159.988%20318.582c-3.988%204.012-9.43%206.25-15.082%206.25s-11.094-2.238-15.082-6.25L9.375%20198.113c-12.5-12.5-12.5-32.77%200-45.246l15.082-15.086c12.504-12.5%2032.75-12.5%2045.25%200l75.2%2075.203L348.104%209.781c12.504-12.5%2032.77-12.5%2045.25%200l15.082%2015.086c12.5%2012.5%2012.5%2032.766%200%2045.246zm0%200%22/%3e%3c/svg%3e');
}

.checkbox-uncheck-mixin(@var) {
  @color: escape('@{var}');
  background: no-repeat
    url(~'data:image/svg+xml;charset=US-ASCII,%3csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%20-46%20417.813%20417%22%20enable-background=%22new%200%20-46%20417.813%20417%22%20%3e%3cstyle%20type=%22text/css%22%3e.color%7bfill%3A@{color}%3B%7D%3c/style%3e%3cpath%20class=%22color%22%20d=%22M243.188%20182.86L356.32%2069.726c12.5-12.5%2012.5-32.766%200-45.247L341.238%209.398c-12.504-12.503-32.77-12.503-45.25%200L182.86%20122.528%2069.727%209.374c-12.5-12.5-32.766-12.5-45.247%200L9.375%2024.457c-12.5%2012.504-12.5%2032.77%200%2045.25l113.152%20113.152L9.398%20295.99c-12.503%2012.503-12.503%2032.769%200%2045.25L24.48%20356.32c12.5%2012.5%2032.766%2012.5%2045.247%200l113.132-113.132L295.99%20356.32c12.503%2012.5%2032.769%2012.5%2045.25%200l15.081-15.082c12.5-12.504%2012.5-32.77%200-45.25zm0%200%22/%3e%3c/svg%3e');
}

.custom-checkbox,
.custom-radiobutton {
  color: @blue-whale;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.custom-checkbox {
  &:hover,
  &:focus {
    .custom-checkbox__check_error {
      background-color: @milano-red;

      &:after {
        .checkbox-check-mixin(@milano-red);
      }
    }
  }
}

.custom-radiobutton {
  &:hover,
  &:focus {
    .custom-radiobutton__input:checked + .custom-radiobutton__check {
      border-color: @deep-aqua;
    }
  }
}

.custom-radiobutton_xs {
  font-size: @secondary-font-size;
}

.custom-checkbox_lg {
  font-size: @primary-font-size;
}
.custom-checkbox__input:not(:disabled) {
  & + .custom-checkbox__check,
  & + .custom-radiobutton__check {
    &:hover,
    &:focus {
      border-color: @deep-aqua;
    }
  }
}

.custom-checkbox__check,
.custom-radiobutton__check {
  display: block;
  height: 20px;
  width: 20px;
  border: @border;
  background-color: @white;
  transition: all 0.4s;
  flex-shrink: 0;
  position: relative;
  border-radius: @border-radius;

  &:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.custom-radiobutton__check {
  border-radius: 50%;
  &.full-size {
    font-size: 12px;
    height: 18px;
    width: 18px;
    &:after {
      width: 100%;
      height: 100%;
    }
  }
}

.custom-checkbox__check_labeled,
.custom-radiobutton__check_labeled {
  margin-right: 5px;
}

.custom-checkbox_disabled,
.custom-radiobutton_disabled {
  cursor: default;
  opacity: 0.6;
}

.custom-checkbox__input {
  display: none;

  &:checked + .custom-checkbox__check {
    background-color: @deep-aqua;
    &:after {
      .checkbox-check-mixin(@white);
    }
  }

  &:checked + .custom-checkbox__check_error {
    background-color: @milano-red;
    &:after {
      .checkbox-check-mixin(@milano-red);
    }
  }

  &:disabled + .custom-checkbox__check {
    cursor: default;
    opacity: 0.6;
  }
}

.custom-radiobutton__input {
  display: none;

  &:checked + .custom-radiobutton__check {
    &:after {
      background-color: @deep-aqua;
      border-radius: 50%;
    }
  }

  &:checked + .custom-checkbox__check_error {
    background-color: @milano-red;
    &:after {
      .checkbox-check-mixin(@milano-red);
    }
  }

  &:disabled + .custom-radiobutton__check {
    cursor: default;
    opacity: 0.6;
  }
}

.label-checkbox__check {
  margin: 0 10px;
}
