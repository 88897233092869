@import "_variables";

[hidden] {
  display: none !important;
}

.tooltip {
  z-index: 1060 !important;

  div {
    word-break: break-word;
    white-space: pre-line;
    text-align: left;
  }
}

.cdk-overlay-container {
  z-index: 1060;
}

.mat-form-field-appearance-legacy {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    padding: 0;
    width: initial;
    border: none;
    overflow: hidden;
  }

  .mat-form-field-flex {
    align-items: center;
  }

  .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 18px !important;
  }

  .mat-form-field-label-wrapper {
    padding-top: 0;
    top: 0;
  }

  .mat-form-field-label {
    left: 12px;
    top: 7px;
    font-size: @secondary-font-size;
    color: @grey-goose;
    font-family: @main-font2;

    .mat-placeholder-required {
      color: inherit !important;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: @grey-goose;
      opacity: 0.6;
    }
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }
}

input.mat-input-element {
  padding: 6px 12px;
  margin: 0;
  background-color: @white;
}

app-svg {
  font-size: 0;
}

.mat-form-field-suffix .mat-icon-button {
  width: 32px;
  height: 30px;
  background-color: #f9f9f9;
  border-left: @border;
  border-radius: 0;

  .mat-icon {
    height: initial;
    width: initial !important;
  }
}

.mat-form-field-appearance-legacy
  .mat-form-field-suffix
  .datepicker__custom-icon-wrapper
  .mat-icon-button
  .mat-icon {
  font-size: 16px;
}

.cdk-virtual-scroll-viewport {
  /* This will affect the scrollbar globally */
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* color of the scroll thumb */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scroll thumb on hover */
  }
}
