@import "_variables";

.datepicker-block {
  max-width: 100%;
  display: flex;
  border-radius: @border-radius;
  border: @border;
  overflow: hidden;

  &.datepicker-block__display-block {
    display: block;
  }

  .mat-icon {
    height: 37px;
    width: initial !important;
  }

  .mat-mdc-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px !important;
    height: 32px !important;
    font-size: 0 !important;
    background-color: @soft-peach;
    border-left: @border;
    border-radius: 0;

    svg {
      width: 13px;
      height: 13px;
    }

    &:hover .mat-mdc-button-persistent-ripple::before {
      opacity: 0;
    }
  }

  .mat-mdc-text-field-wrapper {
    &:hover,
    input:focus {
      background-color: @magnolia;
    }

    &:has(input:focus) {
      border: 1px solid @deep-aqua;
      background-color: @magnolia;
    }

    input:focus::placeholder {
      color: transparent;
    }
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.datepicker-block,
.daterangepicker-block {
  .mdc-text-field--filled {
    border-radius: var(--mdc-filled-text-field-container-shape);
  }

  .mat-mdc-text-field-wrapper {
    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: @white;
      opacity: 1;
    }

    .mat-mdc-form-field-flex {
      align-items: center;
      font-size: small;

      .mat-mdc-form-field-infix {
        display: inline-flex;
        align-items: center;

        input:disabled {
          background-color: transparent;
        }
      }
    }
  }
}

.datepicker-block_error {
  border-color: @milano-red;
}

.datepicker-block_narrow {
  width: 130px;
}

.datepicker-split {
  width: 134px;
}

.datepicker-block_wide {
  width: 400px;
}

.datepicker-block_middle {
  width: 197.5px;
}

.datepicker-block__btn {
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.datepicker-block__input {
  border-right: @border;
  width: calc(100% - 30px);
}

app-fiscal-header + .mat-calendar-content {
  mat-year-view .mat-calendar-body-label[colspan="4"] {
    display: none !important;
  }
}

//Mat date range picker customizations
.daterangepicker-block {
  .mat-form-field-infix {
    display: inline-flex;
    align-items: center;
  }

  &.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 50%;
    transform: translateY(-50%) perspective(100px);
  }

  //Date picker icon size
  .mat-datepicker-toggle {
    .mat-mdc-button-base {
      width: 30px;
      height: 31.3333px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-mdc-icon-button svg {
      width: 20px;
    }
  }

  .mat-date-range-input-start-wrapper {
    margin-left: 12px;

    .mat-date-range-input-inner {
      top: 51%;
      transform: translateY(-50%);
    }
  }

  .mat-date-range-input-inner,
  .mat-date-range-input-mirror {
    font-size: @secondary-font-size !important;
    vertical-align: middle !important;
  }

  input {
    &:focus {
      border: none;
    }

    &:hover:not(:disabled, :read-only),
    &:focus {
      background-color: transparent;
    }
  }
}

//Selection states overrides
.projectbuilder-modal-open {
  .mat-calendar-body-selected,
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(
      .mat-calendar-body-selected,
      .mat-calendar-body-comparison-identical
    ) {
    background-color: @deep-aqua !important;
    color: @white;
  }

  .mat-calendar-body-today:not(
      .mat-calendar-body-selected,
      .mat-calendar-body-comparison-identical
    ) {
    border-color: #00000061;
  }

  .mat-calendar-body-in-range::before {
    background-color: rgba(@deep-aqua, 0.1);
  }

  .mat-mdc-icon-button svg {
    fill: #4c4e51;
  }

  //Mat date range picker action buttons
  .mat-datepicker-actions {
    .action-btn {
      border-radius: 8px;
      padding: 0 10px;
      line-height: 32px;
    }

    .mat-mdc-raised-button {
      letter-spacing: 0;
    }

    .action-btn_default {
      line-height: 30px;
      border: 1.5px solid #ccd4d9;
    }
  }

  .mat-mdc-raised-button.action-btn_success {
    .button(@white, @deep-aqua);
  }
}
