@import '_variables';

.toolbar__panel {
  &:not(.toolbar__panel_outer) {
    padding: 10px;
  }

  &:not(.toolbar__panel_not-bordered) {
    border-top: @border;
  }
}

.toolbar__panel_form {
  margin-bottom: -10px;
}

.toolbar__panel_outer {
  padding: 10px 0;
}

.toolbar__panel-bottom {
  border-bottom: @border;
}

.toolbar__panel_negative-margin {
  margin-bottom: -1px;
}
